import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IAddress from 'models/IAddress';

import { IPostalCodeData } from '../../index';

export interface IGetAddressRequestAction {
  data: {
    postalCode: string;
  };
  functions: {
    error(err: any): void;
    setFields(address: IAddress): void;
  };
}

function getPostalCodeRequest(
  draft: Draft<IPostalCodeData>,
  _: PayloadAction<IGetAddressRequestAction>,
) {
  draft.getAddress.config.isLoading = true;
}

export default getPostalCodeRequest;
