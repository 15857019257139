import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { IDownloadTicketAction } from '../reducers/downloadTicket';

function* downloadTicket(action: PayloadAction<IDownloadTicketAction>) {
  try {
    const response: AxiosResponse = yield call(
      httpClient.post,
      `orders/tickets/${action.payload.data.paymentId}/generate`,
      {},
      { responseType: 'blob' },
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/pdf',
      }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.download = `boleto-${action.payload.data.ourNumber}`;
    document.body.appendChild(link);
    link.click();
    action.payload.functions.success();
  } catch (err) {
    action.payload.functions.error(err);
  }
}

export default downloadTicket;
