import { useCallback, useRef, useState } from 'react';

import { FiPrinter } from 'react-icons/fi';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';

import ICustomerEventVoucher from 'models/ICustomerEventVoucher';

import ComponentButtonBase from 'components/button/Base';

import {
  Card,
  Detail,
  Details,
  Identifier,
  IdentifierNumber,
  InnerContainer,
  Item,
  Label,
  PrintButton,
  QrCode,
  Small,
  Value,
  VoucherDetail,
} from './styles';

interface IVoucherItemProps {
  handleOpenDetails: () => void;
  voucher: ICustomerEventVoucher;
}

const VoucherItem = ({
  handleOpenDetails,
  voucher,
}: IVoucherItemProps): JSX.Element => {
  const [printStyle, setPrintStyle] = useState<boolean>(false);
  const componentToPrintRef = useRef(null);

  const printVoucher = useReactToPrint({
    content: () => componentToPrintRef.current,
    pageStyle: `
      @page {
        margin: 25mm 25mm;
      }
    `,
  });

  const handlePrint = useCallback(() => {
    setPrintStyle(true);
    setTimeout(() => {
      printVoucher();
      setPrintStyle(false);
    }, 250);
  }, [printVoucher]);

  return (
    <InnerContainer ref={componentToPrintRef}>
      <Card onClick={handleOpenDetails} printStyle={printStyle}>
        <Details>
          <Detail>
            <Item>
              <Label hasTitle>Nome do evento</Label>
              <Value>{voucher.event.name}</Value>
            </Item>
            <Item>
              <Label>Data</Label>
              <Value>{voucher.event.date}</Value>
            </Item>
          </Detail>

          <Detail>
            <Item>
              <Label>Forma de pagamento</Label>
              <Value>{voucher.order.paymentMethod}</Value>
            </Item>
            <Item>
              <Label>Valor total</Label>
              <Value>{`R$${voucher.order.amount} em ${voucher.order.installments}x`}</Value>
            </Item>
          </Detail>

          <Detail>
            <Item>
              <Label>Nome do participante</Label>
              <Value>{voucher.participant.name}</Value>
            </Item>
            <Item>
              <Label>Idade</Label>
              <Value>{voucher.participant.age}</Value>
            </Item>
            <Item>
              <Label>Sexo</Label>
              <Value capitalize>{voucher.participant.gender}</Value>
            </Item>
            <Item>
              <Label>Status</Label>
              <Value>{voucher.order.status}</Value>
            </Item>
          </Detail>
        </Details>

        <Identifier>
          <QrCode>
            <QRCode size={72} value={voucher.identifier} />
          </QrCode>
          <IdentifierNumber>
            <Small>Número identificador</Small>
            {voucher.identifier}
          </IdentifierNumber>
        </Identifier>

        <VoucherDetail>
          <ComponentButtonBase
            onClick={handleOpenDetails}
            type="submit"
            width="10rem"
          >
            Ver pagamentos
          </ComponentButtonBase>
        </VoucherDetail>
      </Card>

      <PrintButton onClick={handlePrint} title="Imprimir voucher">
        <FiPrinter size={18} />
      </PrintButton>
    </InnerContainer>
  );
};

export default VoucherItem;
