import { FiFileText } from 'react-icons/fi';

import ICustomerEvent from 'models/ICustomerEvent';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { MyEventsItemsBody } from '../styles';

interface IItemBodyProps {
  customerEvent: ICustomerEvent;
  handleOpenDetails: (data: { id: number; name: string }) => void;
  position: number;
}

const ItemBody = ({
  customerEvent,
  handleOpenDetails,
  position,
}: IItemBodyProps): JSX.Element => {
  return (
    <MyEventsItemsBody addColorRow={!!(position % 2 === 0)}>
      <Tables.ItemBody center>{customerEvent.id}</Tables.ItemBody>
      <Tables.ItemBody>{customerEvent.name}</Tables.ItemBody>
      <Tables.ItemBody>{customerEvent.date.start}</Tables.ItemBody>
      <Tables.ItemBody>
        <Tables.ItemActions>
          <Tables.ItemAction
            onClick={() =>
              handleOpenDetails({
                id: customerEvent.id,
                name: customerEvent.name,
              })
            }
          >
            <FiFileText color={colors.lightBlue900} size={20} />
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </MyEventsItemsBody>
  );
};

export default ItemBody;
