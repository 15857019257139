import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetEventInfoRequest from 'services/httpClient/request/event/IGetEventInfoRequest';
import IGetEventInfoResponse from 'services/httpClient/responses/event/IGetEventInfoResponse';

import { eventActions } from '../index';
import { IGetEventInfoRequestAction } from '../reducers/getInfo/request';

function* getInfo(action: PayloadAction<IGetEventInfoRequestAction>) {
  try {
    const params: IGetEventInfoRequest = {
      responsibleToken: action.payload.data.responsibleToken,
    };

    const response: AxiosResponse<IGetEventInfoResponse> = yield call(
      httpClient.get,
      `events/${action.payload.data.eventId}/privates`,
      { params },
    );
    if (response.data.participant.isBought) {
      action.payload.functions.alreadyBought();
    }
    yield put(eventActions.getInfoSuccess(response.data));
  } catch (err) {
    yield put(eventActions.getInfoFailure());
    action.payload.functions.notFound();
  }
}

export default getInfo;
