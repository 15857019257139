import { createSlice } from '@reduxjs/toolkit';
import IAddress from 'models/IAddress';

import reducers from './reducers';

export interface IPostalCodeData {
  getAddress: {
    address: IAddress;
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IPostalCodeData = {
  getAddress: {
    config: {
      isLoading: false,
    },
    address: {
      city: '',
      neighborhood: '',
      state: '',
      street: '',
    },
  },
};

const postalCodeSlice = createSlice({
  name: '@postalCode',
  initialState,
  reducers,
});

export const postalCodeActions = postalCodeSlice.actions;
export const postalCodeReducers = postalCodeSlice.reducer;
