import IInstitution from './IInstitution';
import IParticipant from './IParticipant';

export enum EEventPaymentMethodType {
  CreditCard = 'CARTAO_CREDITO',
  DebitCard = 'CARTAO_DEBITO',
  Pix = 'PIX',
  Ticket = 'BOLETO',
}

interface IEventPaymentMethod {
  amount: {
    adult: {
      installment: number;
      noInstallment: number;
    };
    children: {
      installment: number;
      noInstallment: number;
    } | null;
  };
  id: number;
  name: string;
  quantityInstallments: number;
  type: EEventPaymentMethodType;
}

interface IEvent {
  age: { children: number; max: number | null; min: number | null };
  allowInstallmentsAfter: boolean;
  date: Date;
  id: number;
  installmentsDateLimit: string | null;
  institution: IInstitution;
  location: { city: string | null; name: string; state: string | null };
  name: string;
  participant: IParticipant;
  paymentMethods: IEventPaymentMethod[];
  picture: string;
  quantityPurchase: number | null;
  restriction: { purchase: boolean };
  terms: string | null;
}

export type { IEventPaymentMethod };
export default IEvent;
