import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div``;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
`;

export const FormContent = styled.div`
  align-items: center;
  display: grid;
  margin: 2rem auto 0;
  max-width: 25rem;
`;

export const Title = styled.h1`
  color: ${colors.darkBlue900};
  font-size: 1.875rem;
  font-weight: 800;
`;

export const Text = styled.p`
  font-size: 1.175rem;
  line-height: 1.25rem;
  margin: 1.5rem 0;
`;

export const Actions = styled.div`
  display: grid;
  margin: 2.5rem auto 0;
  max-width: 18rem;
`;

export const FormTitle = styled.h2`
  color: ${colors.darkBlue900};
  font-weight: 700;
  margin-bottom: 0.25rem;
`;

export const FormSubtitle = styled.p`
  color: ${colors.gray500};
  font-size: 0.875rem;
  margin-bottom: 2rem;
`;

export const Observation = styled.p`
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 2rem;
  text-align: center;
`;
