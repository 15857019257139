import { Transition } from 'react-spring';

import { Container } from './styles';
import ToastElement from './ToastElement';

interface IMessages {
  description?: string;
  duration?: number;
  id: string;
  title: string;
  type?: 'success' | 'error' | 'info';
}

interface IToastProps {
  messages?: IMessages[];
}

const Toast = ({ messages = [] }: IToastProps): JSX.Element => {
  return (
    <Container>
      <Transition
        enter={{
          opacity: 1,
          right: '0%',
        }}
        from={{
          opacity: 0,
          right: '-120%',
        }}
        items={messages}
        keys={(message: IMessages) => message.id}
        leave={{
          opacity: 0,
          right: '-120%',
        }}
      >
        {(style, item) => (
          <ToastElement key={item.id} message={item} style={style} />
        )}
      </Transition>
    </Container>
  );
};

Toast.defaultProps = {
  messages: [],
};
export default Toast;
