import { useCallback, useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { FormHandles, SubmitHandler } from '@unform/core';
import helpers from 'helpers';
import { EEventPaymentMethodType } from 'models/IEvent';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';
import ComponentInputSimple from 'components/input/Simple';
import ComponentMenu from 'components/Menu';
import ComponentIsVisible from 'components/utils/IsVisible';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import Forms from 'styles/forms';

import {
  Actions,
  CardSuccess,
  Container,
  ContentCardSuccess,
  FirstAccessButton,
  FormContent,
  FormSubtitle,
  FormTitle,
  Observation,
  Text,
  Title,
} from './styles';

interface IAuthFormData {
  email: string;
  password: string;
}

interface IFirstAccessFormData {
  email: string;
  password: string;
}

const authSchemaValidation = Yup.object().shape({
  email: Yup.string()
    .email('Informe um e-mail válido (ex: email@email.com)')
    .required('Informe o e-mail'),
  password: Yup.string().required('Informe a senha'),
});

const firstAccessSchemaValidation = Yup.object().shape({
  email: Yup.string()
    .email('Informe um e-mail válido (ex: email@email.com)')
    .required('Informe o e-mail'),
  password: Yup.string().required('Informe a senha'),
  confirmedPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], 'Senhas são diferentes')
    .required('Informe a confirmação da nova senha'),
});

const EventSuccess = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const authFormRef = useRef<FormHandles>(null);
  const reduxDispatch = useReduxDispatch();
  const [isLogin, setIsLogin] = useState<boolean>(true);

  const state = location.state as {
    eventName: string;
    paymentMethodType: EEventPaymentMethodType | null;
    responsibleEmail: string;
  };

  const isLoadingSignIn = useReduxSelector(authSelectors.signInIsLoading);
  const isLoadingFirstAccess = useReduxSelector(
    authSelectors.firstAccessIsLoading,
  );

  const handleAuthenticate: SubmitHandler<IAuthFormData> = useCallback(
    async data => {
      try {
        authFormRef.current?.setErrors({});
        await authSchemaValidation.validate(data, {
          abortEarly: false,
        });
        reduxDispatch(
          authActions.signInRequest({
            data: {
              email: data.email,
              password: data.password,
            },
            functions: {
              error: (err: any) => {
                helpers.errorHandling(err);
              },
              navigateTo: () => {
                navigate(pages.myAccount, { replace: true });
              },
            },
          }),
        );
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = helpers.getValidationErrors(err);
          authFormRef.current?.setErrors(validationErrors);
        }
      }
    },
    [navigate, reduxDispatch],
  );

  const handleFirstAccess: SubmitHandler<IFirstAccessFormData> = useCallback(
    async data => {
      try {
        authFormRef.current?.setErrors({});
        await firstAccessSchemaValidation.validate(data, {
          abortEarly: false,
        });
        reduxDispatch(
          authActions.firstAccessRequest({
            data: {
              email: data.email,
              password: data.password,
            },
            functions: {
              error: (err: any) => {
                helpers.errorHandling(err);
              },
              navigateTo: () => {
                navigate(pages.myAccount, { replace: true });
              },
            },
          }),
        );
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = helpers.getValidationErrors(err);
          authFormRef.current?.setErrors(validationErrors);
        }
      }
    },
    [navigate, reduxDispatch],
  );

  const handleChangeLogin = useCallback((value: boolean) => {
    setIsLogin(value);
  }, []);

  useEffect(() => {
    if (!state) {
      navigate(pages.auth, { replace: true });
    }
  }, [navigate, state]);

  return (
    <Container>
      <ComponentMenu />

      <CardSuccess>
        <ContentCardSuccess>
          <Title>Reserva feita com sucesso! 🥳</Title>
          <ComponentIsVisible
            when={state.paymentMethodType === EEventPaymentMethodType.Ticket}
          >
            <Text>
              {`A sua reserva para o evento ${state.eventName} foi efetuada com sucesso. Você receberá o primeiro boleto através do seu e-mail em até 2 dias úteis.`}
            </Text>
          </ComponentIsVisible>
          <ComponentIsVisible
            when={
              state.paymentMethodType === EEventPaymentMethodType.CreditCard
            }
          >
            <Text>
              {`A sua reserva para o evento ${state.eventName} foi efetuada com sucesso e o
              pagamento está sob análise, fique atento ao seu e-mail para mais
              informações.`}
            </Text>
          </ComponentIsVisible>

          <Observation>
            Você também pode acessar sua conta na plataforma para ver mais
            detalhes da sua reserva.
          </Observation>
        </ContentCardSuccess>
      </CardSuccess>

      <ComponentIsVisible when={isLogin}>
        <FormContent>
          <FormTitle>Efetuar login</FormTitle>
          <FormSubtitle>
            Preencha os campos abaixo para efetuar login
          </FormSubtitle>
          <Forms.Unform onSubmit={handleAuthenticate} ref={authFormRef}>
            <Forms.Grid>
              <ComponentInputSimple
                defaultValue={state.responsibleEmail}
                disabled
                label="E-mail"
                name="email"
              />
            </Forms.Grid>
            <Forms.Grid>
              <ComponentInputPassword label="Senha" name="password" />
            </Forms.Grid>

            <Actions>
              <ComponentButtonBase
                disabled={isLoadingSignIn}
                height="2.5rem"
                isLoading={isLoadingSignIn}
                type="submit"
              >
                Entrar
              </ComponentButtonBase>
              <FirstAccessButton
                disabled={isLoadingSignIn}
                onClick={() => handleChangeLogin(false)}
                type="button"
              >
                É o primeiro acesso? Clique aqui!
              </FirstAccessButton>
            </Actions>
          </Forms.Unform>
        </FormContent>
      </ComponentIsVisible>

      <ComponentIsVisible when={!isLogin}>
        <FormContent>
          <FormTitle>Primeiro acesso</FormTitle>
          <FormSubtitle>
            Preencha os campos abaixo para efetuar o primeiro acesso
          </FormSubtitle>
          <Forms.Unform onSubmit={handleFirstAccess} ref={authFormRef}>
            <Forms.Grid>
              <ComponentInputSimple
                defaultValue={state.responsibleEmail}
                disabled
                label="E-mail"
                name="email"
              />
            </Forms.Grid>
            <Forms.Grid>
              <ComponentInputPassword label="Senha" name="password" />
            </Forms.Grid>
            <Forms.Grid>
              <ComponentInputPassword
                label="Confirmar senha"
                name="confirmedPassword"
              />
            </Forms.Grid>

            <Actions>
              <ComponentButtonBase
                disabled={isLoadingFirstAccess}
                height="2.5rem"
                isLoading={isLoadingFirstAccess}
                type="submit"
              >
                Entrar
              </ComponentButtonBase>
              <FirstAccessButton
                disabled={isLoadingFirstAccess}
                onClick={() => handleChangeLogin(true)}
                type="button"
              >
                Voltar
              </FirstAccessButton>
            </Actions>
          </Forms.Unform>
        </FormContent>
      </ComponentIsVisible>
    </Container>
  );
};

export default EventSuccess;
