import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerData } from '../../index';

export interface IGetAllCustomerEventRequestAction {
  functions: {
    error: (err: any) => void;
  };
}

function getAllEventRequest(
  draft: Draft<ICustomerData>,
  _: PayloadAction<IGetAllCustomerEventRequestAction>,
) {
  draft.getAllEvent.config.emptyMessage = '';
  draft.getAllEvent.config.errorMessage = '';
  draft.getAllEvent.config.isLoading = true;
  draft.getAllEvent.list = [];
}

export default getAllEventRequest;
