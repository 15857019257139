import React from 'react';

import General from 'styles/general';

import { Bold, Container, ListText, ListTitle, Text, Title } from './styles';

const Terms: React.FC = () => {
  return (
    <Container>
      <General.PageTitleContainer>
        <Title>Termos de uso</Title>
      </General.PageTitleContainer>
      <Text>
        Este documento visa registrar a manifestação livre, informada e
        inequívoca, enquanto na qualidade de consumidor/cliente titular de
        dados, adiante denominado TITULAR, concorda com o tratamento dos meus
        dados pessoais e/ou do(a) meu(minha) filho(a), para finalidade
        específica da relação comercial havida com a empresa MSL Eventos e
        Produções Ltda. – aqui na qualidade e adiante denominada CONTROLADORA,
        em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados
        Pessoais (LGPD).
      </Text>
      <Text>
        Ao manifestar minha aceitação para com o presente termo, dou meu
        consentimento e concordo que a Controladora tome decisões referentes ao
        tratamento de meus dados pessoais ou de minha empresa, necessários ao
        usufruto dos serviços/produtos da CONTROLADORA, bem como realize o
        tratamento de tais dados, envolvendo operações como as que se referem a
        coleta, produção, recepção, classificação, utilização, acesso,
        reprodução, transmissão, distribuição, processamento, arquivamento,
        armazenamento, eliminação, avaliação ou controle da informação,
        modificação, comunicação, transferência, difusão ou extração.
      </Text>
      <ListTitle marginTop>
        <Bold>1. Dados Pessoais</Bold> – A Controladora fica autorizada a tomar
        decisões referentes ao tratamento e a realizar o tratamento dos
        seguintes dados pessoais do Titular, imprescindíveis para manutenção da
        relação contratual:
      </ListTitle>
      <ListText>1. Nome completo;</ListText>
      <ListText>2. Nome empresarial;</ListText>
      <ListText>3. Data de nascimento;</ListText>
      <ListText>4. Número e imagem da Carteira de Identidade (RG);</ListText>
      <ListText>
        5. Número e imagem do Cadastro de Pessoas Físicas (CPF);
      </ListText>
      <ListText>
        6. Número e imagem da Carteira Nacional de Habilitação (CNH);
      </ListText>
      <ListText>
        7. Número do Cadastro Nacional de Pessoas Jurídicas (CNPJ);
      </ListText>
      <ListText>8. Fotografia 3x4;</ListText>
      <ListText>9. Estado civil;</ListText>
      <ListText>10. Nível de instrução ou escolaridade;</ListText>
      <ListText>11. Endereço completo;</ListText>
      <ListText>
        12. Números de telefone, WhatsApp e endereços de e-mail;
      </ListText>
      <ListText>13. Banco, agência e número de contas bancárias;</ListText>
      <ListText>
        14. Bandeira, número, validade e código de cartões de crédito;
      </ListText>
      <ListText>
        15. Nome de usuário e senha específicos para uso de software de
        cadastros dos serviços/produtos do Controlador;
      </ListText>
      <ListText>
        16. Comunicação, verbal e escrita, mantida entre o Titular e o
        Controlador;
      </ListText>
      <ListText>
        17. Dados e condições pessoais da saúde da criança e ou adulto,
        limitações funcionais corporais, necessidade especial, alergias
        (insetos; alimentar; medicamentos, cosméticos, protetor solar, produtos
        de curativos), fobias, medos, restrição cardiovascular, doenças graves,
        diabetes, bronquite, convulsão, infecção de pele, sonambulismo, enurese
        etc.;
      </ListText>
      <ListText>
        18. Dados de terceiros como; médicos em geral (pediatra,
        cardiovascular), fisioterapeuta, etc...;
      </ListText>

      <ListTitle marginTop>
        <Bold>2. Finalidades do Tratamento dos Dados</Bold> – O tratamento dos
        dados pessoais listados neste termo tem as seguintes finalidades:
      </ListTitle>
      <ListText>
        1. Permitir a identificação e o contato entre a Controladora e o Titular
        para fins de relacionamento comercial;
      </ListText>
      <ListText>
        2. Possibilitar que a Controladora elabore contratos comerciais e emita
        cobranças contra o Titular;
      </ListText>
      <ListText>
        3. Possibilitar que a Controladora envie ou forneça ao Titular seus
        produtos e serviços, de forma remunerada ou gratuita;
      </ListText>
      <ListText>
        4. Permitir que a Controladora alimente seu banco interno de cadastro de
        cliente, para fins de conhecimento do vínculo da relação comercial;
      </ListText>
      <ListText>
        5. Possibilitar que a Controladora utilize tais dados em Pesquisas de
        Mercado;
      </ListText>
      <ListText>
        6. Possibilitar que a Controladora utilize tais dados na inscrição,
        divulgação, premiação dos interessados participantes de campanhas ou
        eventos por ela promovidos;
      </ListText>
      <ListText>
        7. Possibilitar que a Controladora utilize tais dados na elaboração de
        relatórios e emissão de produtos e serviços;
      </ListText>
      <ListText>
        8. Possibilitar que a Controladora utilize tais dados para suas peças de
        Comunicação;
      </ListText>
      <ListText>
        9. Possibilitar que a Controladora utilize tais dados para a emissão de
        Notas Fiscais e documentos financeiros correlatos;
      </ListText>
      <ListText>
        10. Possibilitar que a Controladora utilize tais dados para facilitar a
        prestação de serviços diversos além dos primariamente contratados, desde
        que o cliente também demonstre interesse em contratar novos serviços;
      </ListText>
      <ListText>
        11. Possibilitar que a Controladora utilize tais dados para manter banco
        de dados de clientes para facilitar o contato futuro para oferta de
        produtos/serviços;
      </ListText>
      <ListText>
        12. Para fins de prestação de socorro em eventual ocorrência de algo
        ligado à saúde e bem-estar, lesão etc.;
      </ListText>

      <ListTitle marginTop>
        <Bold>3. Compartilhamento de Dados</Bold> - A Controladora fica
        autorizada a compartilhar os dados pessoais do Titular com outros
        agentes de tratamento de dados, caso seja necessário para as finalidades
        listadas neste termo, observados os princípios e as garantias
        estabelecidas pela Lei nº 13.709.
      </ListTitle>

      <ListTitle marginTop>
        <Bold>4. Segurança dos Dados</Bold> – A CONTROLADORA adotará todas as
        medidas de segurança, técnicas e administrativas aptas a proteger os
        dados pessoais de acessos não autorizados e de situações acidentais ou
        ilícitas de destruição, perda, alteração, comunicação ou qualquer forma
        de tratamento inadequado ou ilícito.
      </ListTitle>

      <ListTitle marginTop>
        Em conformidade ao art. 48 da Lei nº 13.709, o Controlador comunicará ao
        Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência
        de incidente de segurança que possa acarretar risco ou dano relevante ao
        Titular
      </ListTitle>

      <ListTitle marginTop>
        <Bold>5. Término do Tratamento e Eliminação dos Dados</Bold> - A
        Controladora poderá manter e tratar os dados pessoais do Titular durante
        todo o período em que forem pertinentes ao alcance das finalidades
        listadas neste termo para o interesse comum das partes.
      </ListTitle>

      <ListTitle marginTop>
        Estou ciente de que poderei solicitar via e-mail ou correspondência à
        CONTROLADORA, a qualquer momento, a eliminação dos meus dados pessoais,
        ficando ciente de que poderá ser inviável à Controladora continuar o
        fornecimento de produtos ou serviços a partir da eliminação dos dados
        pessoais.
      </ListTitle>

      <ListTitle marginTop>
        <Bold>6. Direitos do Titular</Bold> - O Titular tem direito a obter da
        Controladora, em relação aos dados por ele tratados, a qualquer momento
        e mediante requisição:
      </ListTitle>
      <ListText marginTop>
        I - confirmação da existência de tratamento; II - acesso aos dados; III
        - correção de dados incompletos, inexatos ou desatualizados; IV -
        anonimização, bloqueio ou eliminação de dados desnecessários, excessivos
        ou tratados em desconformidade com o disposto na Lei nº 13.709; V -
        portabilidade dos dados a outro fornecedor de serviço ou produto,
        mediante requisição expressa e observados os segredos comercial e
        industrial, de acordo com a regulamentação do órgão controlador; V -
        portabilidade dos dados a outro fornecedor de serviço ou produto,
        mediante requisição expressa, de acordo com a regulamentação da
        autoridade nacional, observados os segredos comercial e industrial; VI -
        eliminação dos dados pessoais tratados com o consentimento do titular,
        exceto nas hipóteses previstas no art. 16 da Lei nº 13.709; VII -
        informação das entidades públicas e privadas com as quais o controlador
        realizou uso compartilhado de dados; VIII - informação sobre a
        possibilidade de não fornecer consentimento e sobre as consequências da
        negativa; IX - revogação do consentimento, nos termos do § 5º do art. 8º
        da Lei nº 13.709.
      </ListText>

      <Text>
        Este consentimento poderá ser revogado pelo Titular, a qualquer momento,
        mediante solicitação via e-mail ou correspondência ao Controlador.
      </Text>
    </Container>
  );
};

export default Terms;
