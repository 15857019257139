import { useCallback, useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import EOrderStatus from 'enums/orderStatus';
import helpers from 'helpers';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBack from 'components/button/Back';
import ComponentEmpty from 'components/utils/Empty';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import InstallmentItem from './Item';
import {
  Content,
  Details,
  DetailsHeader,
  Hr,
  Info,
  Name,
  NameValue,
  Small,
  Subtitle,
  Value,
} from './styles';

interface ILocationState {
  eventId: number;
}

const MyEventVoucherDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();
  const params = useParams();
  const { state } = useLocation();
  const voucherDetails = useReduxSelector(
    customerSelectors.getEventVoucherDetails,
  );
  const voucherDetailsIsLoading = useReduxSelector(
    customerSelectors.getEventVoucherDetailsIsLoading,
  );

  const locationState = state as ILocationState;

  const handleLoadVoucherDetails = useCallback(() => {
    reduxDispatch(
      customerActions.getEventVoucherDetailsRequest({
        data: {
          voucherId: params.id as string,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
          goBack: () => {
            navigate(pages.eventVouchers(locationState.eventId), {
              replace: true,
            });
          },
        },
      }),
    );
  }, [locationState.eventId, navigate, params.id, reduxDispatch]);

  useEffect(() => {
    handleLoadVoucherDetails();
  }, [handleLoadVoucherDetails]);

  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle hasBackButton>
          <ComponentButtonBack />
          Detalhes do voucher
        </General.PageTitle>
      </General.PageTitleContainer>

      <Details>
        <DetailsHeader>
          <Name>
            <Small>Visualizando o voucher nº</Small>
            <NameValue isLoading={voucherDetailsIsLoading}>
              {`Voucher nº ${voucherDetails?.id}`}
            </NameValue>
          </Name>
        </DetailsHeader>

        <Content>
          <Hr />

          <Subtitle>Dados do sacado</Subtitle>
          <Info>
            <Small>Nome do responsável</Small>
            <Value isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.responsible.name}
            </Value>
          </Info>
          <Info>
            <Small>Acampante</Small>
            <Value isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.participant.name}
            </Value>
          </Info>
          <Info>
            <Small>E-mail</Small>
            <Value isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.responsible.email}
            </Value>
          </Info>
          <Info>
            <Small>Telefone</Small>
            <Value isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.responsible.phone}
            </Value>
          </Info>

          <Hr />
          <Subtitle>Dados do pagamento</Subtitle>
          <Info>
            <Small>Meio de pagamento</Small>
            <Value isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.order.paymentMethod}
            </Value>
          </Info>
          <Info>
            <Small>Status</Small>

            <Value isLoading={voucherDetailsIsLoading}>
              <ComponentIsVisible
                when={voucherDetails?.order.status === EOrderStatus.Cancelled}
              >
                Cancelado
              </ComponentIsVisible>
              <ComponentIsVisible
                when={voucherDetails?.order.status === EOrderStatus.Overdue}
              >
                Vencido
              </ComponentIsVisible>
              <ComponentIsVisible
                when={voucherDetails?.order.status === EOrderStatus.Paid}
              >
                Pago
              </ComponentIsVisible>
              <ComponentIsVisible
                when={voucherDetails?.order.status === EOrderStatus.Pending}
              >
                Aguardando pagamento
              </ComponentIsVisible>
              <ComponentIsVisible
                when={
                  voucherDetails?.order.status === EOrderStatus.PartiallyPaid
                }
              >
                Parcialmente pago
              </ComponentIsVisible>
            </Value>
          </Info>
          <Info>
            <Small>Valor base cobrado</Small>
            <Value
              isLoading={voucherDetailsIsLoading}
            >{`R$ ${voucherDetails?.order.amount}`}</Value>
          </Info>
          <Info>
            <Small>Parcelas</Small>
            <Value isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.order.installments}
            </Value>
          </Info>

          <Hr />
          <Subtitle>Dados da reserva</Subtitle>
          <Info>
            <Small>Reserva</Small>
            <Value isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.identifier}
            </Value>
          </Info>
          <Info>
            <Small>Evento</Small>
            <Value isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.event.name}
            </Value>
          </Info>
          <Info>
            <Small>Nome do participante</Small>
            <Value isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.participant.name}
            </Value>
          </Info>
          <Info>
            <Small>Idade</Small>
            <Value
              isLoading={voucherDetailsIsLoading}
            >{`${voucherDetails?.participant.age} anos`}</Value>
          </Info>
          <Info>
            <Small>Sexo</Small>

            <Value capitalize isLoading={voucherDetailsIsLoading}>
              {voucherDetails?.participant.gender}
            </Value>
          </Info>
        </Content>
        <Hr />

        <Subtitle>Parcelas</Subtitle>

        <Tables.Items marginTop="0.5rem">
          <InstallmentItem.Header />

          <ComponentLoadingList show={voucherDetailsIsLoading} />

          <ComponentIsVisible when={!voucherDetailsIsLoading}>
            <ComponentIsVisible when={!!voucherDetails?.order.payments.length}>
              {voucherDetails?.order.payments.map((payment, position) => (
                <InstallmentItem.Body
                  key={payment.id}
                  length={voucherDetails?.order.payments.length}
                  payment={payment}
                  position={position}
                />
              ))}
            </ComponentIsVisible>

            <ComponentIsVisible when={!voucherDetails?.order.payments.length}>
              <ComponentEmpty
                message="Nenhuma parcela encontrada"
                show={!!voucherDetails?.order.payments.length}
              />
            </ComponentIsVisible>
          </ComponentIsVisible>
        </Tables.Items>
      </Details>
    </Containers.Global>
  );
};

export default MyEventVoucherDetails;
