function formatLocation({
  city,
  name,
  state,
}: {
  city: string | null;
  name: string;
  state: string | null;
}): string {
  if (city === null && state === null) {
    return name;
  }
  if (!!city && state === null) {
    return `${name} - ${city}`;
  }
  if (city === null && !!state) {
    return `${name} - ${state}`;
  }
  return `${name} - ${city}, ${state}`;
}

export default formatLocation;
