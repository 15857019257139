import { Br, Container, Message, Warning } from './styles';

const InternalError: React.FC = () => {
  return (
    <Container>
      <Warning>Erro 500</Warning>
      <Message>
        Estamos passando por algumas instabilidades, pedimos desculpas pelo
        transtorno.
        <Br />
        Tente novamente mais tarde
      </Message>
    </Container>
  );
};

export default InternalError;
