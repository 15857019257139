import { useCallback, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { FormHandles, SubmitHandler } from '@unform/core';
import helpers from 'helpers';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';
import ComponentInputSimple from 'components/input/Simple';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import Containers from 'styles/containers';
import Forms from 'styles/forms';

import ForgotPassword, { IForgotPasswordRefProps } from './ForgotPassword';
import {
  Actions,
  Content,
  ForgotPasswordButton,
  Subtitle,
  Title,
} from './styles';

interface IAuthFormData {
  email: string;
  password: string;
}

const authSchemaValidation = Yup.object().shape({
  email: Yup.string()
    .email('Informe um e-mail válido (ex: email@email.com)')
    .required('Informe o e-mail'),
  password: Yup.string().required('Informe a senha'),
});

const Auth = (): JSX.Element => {
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(authSelectors.signInIsLoading);

  const authFormRef = useRef<FormHandles>(null);
  const forgotPasswordRef = useRef<IForgotPasswordRefProps>(null);

  const openForgotPassword = useCallback(() => {
    forgotPasswordRef.current?.open();
  }, []);

  const handleAuthenticate: SubmitHandler<IAuthFormData> = useCallback(
    async data => {
      try {
        authFormRef.current?.setErrors({});
        await authSchemaValidation.validate(data, {
          abortEarly: false,
        });
        reduxDispatch(
          authActions.signInRequest({
            data: {
              email: data.email,
              password: data.password,
            },
            functions: {
              error: (err: any) => {
                helpers.errorHandling(err);
              },
              navigateTo: () => {
                navigate(pages.myEvent.list, { replace: true });
              },
            },
          }),
        );
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = helpers.getValidationErrors(err);
          authFormRef.current?.setErrors(validationErrors);
        }
      }
    },
    [navigate, reduxDispatch],
  );

  return (
    <Containers.Global>
      <Content>
        <Title>Efetuar login</Title>
        <Subtitle>Preencha os campos abaixo para efetuar login</Subtitle>

        <Forms.Unform onSubmit={handleAuthenticate} ref={authFormRef}>
          <Forms.Grid>
            <ComponentInputSimple label="E-mail" name="email" />
          </Forms.Grid>
          <Forms.Grid>
            <ComponentInputPassword label="Senha" name="password" />
          </Forms.Grid>

          <Actions>
            <ComponentButtonBase
              disabled={isLoading}
              height="2.5rem"
              isLoading={isLoading}
              type="submit"
            >
              Entrar
            </ComponentButtonBase>
            <ForgotPasswordButton
              disabled={isLoading}
              onClick={openForgotPassword}
              type="button"
            >
              Esqueci minha senha
            </ForgotPasswordButton>
          </Actions>
        </Forms.Unform>
      </Content>

      <ForgotPassword ref={forgotPasswordRef} />
    </Containers.Global>
  );
};

export default Auth;
