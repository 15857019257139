import styled from 'styled-components';

import animations from 'styles/animations';

export const Container = styled.div`
  align-items: center;
  animation: ${animations.shimmer} 3s infinite linear;
  background: linear-gradient(to right, #f6f6f6 4%, #f0f0f0 25%, #f6f6f6 36%);
  background-size: 1000px 100%;
  border-radius: 0.375rem;
  column-gap: 0.5rem;
  display: grid;
  height: 100%;
  min-height: 20rem;
  padding: 0 1rem;
  width: 100%;
`;
