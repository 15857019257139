import { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import helpers from 'helpers';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import { Subtitle } from 'components/modal/styles';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import Forms from 'styles/forms';
import General from 'styles/general';
import Tables from 'styles/tables';

import MyEventsItem from './Item';

const MyEvents = (): JSX.Element => {
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();
  const customerEvents = useReduxSelector(customerSelectors.getAllEventList);
  const isLoading = useReduxSelector(customerSelectors.getAllEventIsLoading);
  const errorMessage = useReduxSelector(
    customerSelectors.getAllEventErrorMessage,
  );
  const emptyMessage = useReduxSelector(
    customerSelectors.getAllEventEmptyMessage,
  );

  const handleLoadEvents = useCallback(() => {
    reduxDispatch(
      customerActions.getAllEventRequest({
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handleOpenDetails = useCallback(
    (data: { id: number; name: string }) => {
      navigate(pages.eventVouchers(data.id), {
        state: { eventName: data.name },
      });
    },
    [navigate],
  );

  useEffect(() => {
    handleLoadEvents();
  }, [handleLoadEvents]);
  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle>
          Meus eventos
          <Subtitle>Confira suas reservas já realizadas.</Subtitle>
        </General.PageTitle>
      </General.PageTitleContainer>

      <Forms.Subtitle color={colors.orange700}>
        Eventos que participei
      </Forms.Subtitle>

      <Tables.Items>
        <MyEventsItem.Header />

        <ComponentLoadingList show={isLoading} />

        <ComponentIsVisible when={!isLoading}>
          <ComponentIsVisible when={!!customerEvents.length}>
            {customerEvents.map((customerEvent, position) => (
              <MyEventsItem.Body
                customerEvent={customerEvent}
                handleOpenDetails={handleOpenDetails}
                key={customerEvent.id}
                position={position}
              />
            ))}
          </ComponentIsVisible>
          <ComponentIsVisible when={!!errorMessage && !isLoading}>
            <ComponentError message={errorMessage} onClick={handleLoadEvents} />
          </ComponentIsVisible>

          <ComponentIsVisible when={!customerEvents.length && !errorMessage}>
            <ComponentEmpty message={emptyMessage} show={!!emptyMessage} />
          </ComponentIsVisible>
        </ComponentIsVisible>
      </Tables.Items>
    </Containers.Global>
  );
};

export default MyEvents;
