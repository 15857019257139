import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import ComponentIsVisible from 'components/utils/IsVisible';

import { Backdrop, Container } from './styles';

export interface IComponentModalBaseRefProps {
  close(): void;
  open(): void;
}

interface IComponentModalBaseProps {
  children: ReactNode;
  onBackdropClick?(): void;
}

const ComponentModalBase: React.ForwardRefRenderFunction<
  IComponentModalBaseRefProps,
  IComponentModalBaseProps
> = ({ children, onBackdropClick }, ref) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setIsOpened(true);
  }, []);
  const closeModal = useCallback(() => {
    const animated = document.getElementById('modalContent');
    if (animated) {
      animated.addEventListener('animationend', () => {
        setIsOpened(false);
      });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    close: closeModal,
    open: openModal,
  }));

  useHotkeys('esc', () => closeModal());

  useEffect(() => {
    const body = document.getElementById('mainBody');
    if (body && isOpened) {
      body.classList.add('overflowHidden');
    }
    return () => {
      body?.classList.remove('overflowHidden');
    };
  }, [isOpened]);

  return (
    <ComponentIsVisible when={isOpened}>
      <Container>
        <Backdrop onClick={onBackdropClick} />
        {children}
      </Container>
    </ComponentIsVisible>
  );
};

export default forwardRef(ComponentModalBase);
