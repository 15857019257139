import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const InnerContainer = styled.div`
  position: relative;
`;

interface ICardProps {
  printStyle?: boolean;
}

export const Card = styled.div<ICardProps>`
  border: 1px solid ${colors.transparent};
  border-radius: 0.375rem;
  box-shadow: 0 0 3px 0 ${colors.boxShadow};
  cursor: pointer;
  padding: 0 2rem 2rem;

  ${props =>
    props.printStyle &&
    css`
      border-color: ${colors.gray100};
      box-shadow: none;
    `}
`;

export const Details = styled.div`
  margin-top: 1rem;
`;

export const Detail = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.5rem;
`;

export const Item = styled.div``;

interface ILabelProps {
  hasTitle?: boolean;
}
export const Label = styled.label<ILabelProps>`
  color: ${props => (props.hasTitle ? colors.orange700 : colors.darkGreen800)};
  font-size: 0.75rem;
`;

interface IValueProps {
  capitalize?: boolean;
}

export const Value = styled.p<IValueProps>`
  color: ${colors.gray500};
  font-size: 0.75rem;
  margin-top: 0.25rem;
  text-transform: ${props => (props.capitalize ? 'capitalize' : 'initial')};
`;

export const Identifier = styled.div`
  align-items: center;
  display: grid;
  justify-content: center;
  margin-top: 2rem;
`;

export const QrCode = styled.div`
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: center;
  margin: 0 auto 0.5rem;
  position: relative;
  width: 5rem;
`;

export const IdentifierNumber = styled.p`
  color: ${colors.black900};
  display: grid;
  font-size: 1.115rem;
  font-weight: 500;
  text-align: center;
`;

export const Small = styled.small`
  color: ${colors.black900};
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
`;

export const PrintButton = styled.button`
  align-items: center;
  background-color: ${colors.darkBlue900};
  border: 0;
  border-radius: 0.375rem;
  color: ${colors.white900};
  display: flex;
  font-size: 0;
  height: 2.25rem;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2.25rem;
  @media screen and (min-width: 768px) {
    &:hover {
      opacity: 0.75;
    }
  }
`;

export const VoucherDetail = styled.div`
  align-items: center;
  display: grid;
  justify-content: center;
  margin-top: 1.5rem;
  @media screen and (min-width: 768px) {
    &:hover {
      opacity: 0.75;
    }
  }
`;
