import styled, { css } from 'styled-components';

import colors from './colors';

interface IItemsProps {
  marginTop?: string;
  removeBorder?: boolean;
}
const Items = styled.div<IItemsProps>`
  background-color: ${colors.white100};
  border: 1px solid ${colors.gray100};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.marginTop ? props.marginTop : '1rem')};
  min-height: 15rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  ${props =>
    props.removeBorder &&
    css`
      border: 0;
    `};
`;

const ItemsHeader = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  min-height: 2.5rem;
  padding: 0 0.5rem;
  width: 100%;
`;

interface IItemHeaderProps {
  center?: boolean;
}
const ItemHeader = styled.label<IItemHeaderProps>`
  color: ${colors.darkBlue900};
  font-size: 0.875rem;
  font-weight: 400;
  width: 100%;
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`;

interface IItemBodyProps {
  addColorRow?: boolean;
}

const ItemsBody = styled.div<IItemBodyProps>`
  align-items: center;
  background-color: ${colors.transparent};
  column-gap: 0.5rem;
  display: grid;
  font-weight: 500;
  min-height: 2.5rem;
  padding: 0 0.5rem;
  width: 100%;
  ${props =>
    props.addColorRow &&
    css`
      background: ${colors.white900};
    `}
`;

const ItemsBodyContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 0.5rem;
  width: 100%;
`;

interface IItemBodyProps {
  active?: boolean;
  bold?: boolean;
  capitalize?: boolean;
  center?: boolean;
  inactive?: boolean;
}

const ItemBody = styled.div<IItemBodyProps>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${colors.gray500};
  display: -webkit-box;
  float: left;
  font-size: 0.875rem;
  line-height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;
  @media (min-width: 768px) {
    -webkit-line-clamp: 3;
  }
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  ${props =>
    props.active &&
    css`
      color: ${colors.darkBlue900};
      font-weight: 600;
    `};
  ${props =>
    props.inactive &&
    css`
      color: ${colors.red700};
    `};
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `};
  ${props =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `};
`;

const ItemTags = styled.div`
  align-items: center;
  column-gap: 0.25rem;
  display: flex;
  grid-column-end: 5;
  grid-column-start: 2;
  width: 100%;
`;

interface IItemTagProps {
  bgColor?: string;
}
const ItemTag = styled.div<IItemTagProps>`
  align-items: center;
  background-color: ${props =>
    props.bgColor ? props.bgColor : colors.black900};
  border-radius: 0.4rem;
  color: ${colors.white900};
  display: flex;
  font-size: 0.75rem;
  height: 1.25rem;
  justify-content: center;
  padding: 0 0.5rem;
`;

const ItemActions = styled.div`
  column-gap: 0.5rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const ItemAction = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  display: flex;
  font-size: 0;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
  @media screen and (min-width: 768px) {
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default {
  Items,
  ItemsHeader,
  ItemHeader,
  ItemsBody,
  ItemsBodyContent,
  ItemBody,
  ItemTags,
  ItemTag,
  ItemActions,
  ItemAction,
};
