import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetCustomerEventVoucherDetailsResponse from 'services/httpClient/responses/customer/IGetCustomerEventVoucherDetailsResponse';

import { customerActions } from '../index';
import { IGetCustomerEventVoucherDetailsRequestAction } from '../reducers/getEventVoucherDetails/request';

function* getEventVoucherDetails(
  action: PayloadAction<IGetCustomerEventVoucherDetailsRequestAction>,
) {
  try {
    const { voucherId } = action.payload.data;
    const response: AxiosResponse<IGetCustomerEventVoucherDetailsResponse> =
      yield call(httpClient.get, `responsibles/events/tickets/${voucherId}`);

    if (response.status === EHttpStatusCodes.NO_CONTENT) {
      action.payload.functions.goBack();
      action.payload.functions.error({
        message: 'Informações do voucher não encontradas',
      });
      return;
    }

    yield put(
      customerActions.getEventVoucherDetailsSuccess({
        data: response.data,
      }),
    );
  } catch (err: any) {
    yield put(customerActions.getEventVoucherDetailsFailure());
    action.payload.functions.error(err);
  }
}

export default getEventVoucherDetails;
