import styled from 'styled-components';

import colors from 'styles/colors';

export const EventName = styled.p`
  color: ${colors.orange700};
  display: grid;
  font-weight: 600;
  justify-content: flex-end;
  row-gap: 0.25rem;
  text-align: right;
`;

export const Small = styled.small`
  color: ${colors.gray200};
  font-size: 0.875rem;
  font-weight: 400;
`;

export const Vouchers = styled.div`
  display: grid;
  margin-top: 1rem;
  row-gap: 1rem;
  @media screen and (min-width: 768px) {
    column-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
