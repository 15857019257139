import { useCallback, useEffect, useRef, useState } from 'react';

import { FiAlertCircle } from 'react-icons/fi';
import ReactSelect, { Props as SelectProps } from 'react-select';

import { useField } from '@unform/core';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Container, Error, Label, SelectContainer } from '../styles';
import { customStyles } from './styles';

export interface IOptionTypeBase<T = any> {
  additional?: T;
  label: string;
  value: number;
}
interface IComponentSelectSimpleProps extends SelectProps<IOptionTypeBase> {
  gridColumn?: string;
  label?: string;
  name: string;
  onLoadOptions?(setLoading: (stateLoading: boolean) => void): Promise<void>;
  options?: IOptionTypeBase[];
  placeholder?: string;
  title?: string;
}

const ComponentSelectSimple = ({
  gridColumn,
  label,
  name,
  onLoadOptions,
  options,
  placeholder,
  title,
  ...rest
}: IComponentSelectSimpleProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectRef = useRef(null);
  const { defaultValue, error, fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: IOptionTypeBase) => option);
        }
        if (!ref.state.selectValue.length) {
          return '';
        }
        return ref.state.selectValue[0];
      },
      setValue: (ref, value) => {
        ref.setValue(value);
      },
      clearValue: ref => {
        ref.clearValue();
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const onSetLoading = useCallback((stateLoading: boolean) => {
    setIsLoading(stateLoading);
  }, []);

  const handleSelectFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleSelectBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    if (onLoadOptions) {
      onLoadOptions(onSetLoading);
    }
  }, [onLoadOptions, onSetLoading]);

  return (
    <Container gridColumn={gridColumn}>
      <ComponentIsVisible when={!!label}>
        <Label>{label}</Label>
      </ComponentIsVisible>

      <SelectContainer
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        title={title}
      >
        <ReactSelect
          defaultValue={defaultValue}
          isDisabled={isLoading}
          isLoading={isLoading}
          loadingMessage={() => 'Buscando'}
          name={name}
          noOptionsMessage={() => 'Sem opções'}
          onBlur={handleSelectBlur}
          onFocus={handleSelectFocus}
          options={options}
          placeholder={isLoading ? 'BUSCANDO' : placeholder}
          ref={selectRef}
          styles={customStyles}
          {...rest}
          isMulti={false}
        />

        <ComponentIsVisible when={!!error}>
          <Error title={error as string}>
            <FiAlertCircle color={colors.red700} size={18} />
          </Error>
        </ComponentIsVisible>
      </SelectContainer>
    </Container>
  );
};

ComponentSelectSimple.defaultProps = {
  label: undefined,
  options: undefined,
  title: undefined,
  placeholder: undefined,
  onLoadOptions: undefined,
  gridColumn: undefined,
};

export default ComponentSelectSimple;
