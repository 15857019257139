import { useCallback, useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import helpers from 'helpers';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBack from 'components/button/Back';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import VoucherItemLoading from 'components/utils/Loading/Voucher';

import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import Containers from 'styles/containers';
import General from 'styles/general';

import VoucherItem from './Item';
import { EventName, Small, Vouchers } from './styles';

interface ILocationState {
  eventName: string;
}

const MyEventVouchers = (): JSX.Element => {
  const reduxDispatch = useReduxDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const eventVouchers = useReduxSelector(
    customerSelectors.getAllEventVoucherList,
  );
  const isLoading = useReduxSelector(
    customerSelectors.getAllEventVoucherIsLoading,
  );
  const errorMessage = useReduxSelector(
    customerSelectors.getAllEventVoucherErrorMessage,
  );

  const loadEventVouchers = useCallback(() => {
    if (!params.id) {
      navigate(pages.myAccount, { replace: true });
      return;
    }
    reduxDispatch(
      customerActions.getAllEventVoucherRequest({
        data: {
          eventId: params.id,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [navigate, params.id, reduxDispatch]);

  const locationState = location.state as ILocationState;

  const handleOpenDetails = useCallback(
    (data: { eventId: number; id: number }) => {
      navigate(pages.eventVoucherDetails(data.id), {
        state: { eventId: data.eventId },
      });
    },
    [navigate],
  );

  useEffect(() => {
    loadEventVouchers();
  }, [loadEventVouchers]);
  return (
    <Containers.Global>
      <General.PageTitleContainer alignItems="flex-start" displayMobile="flex">
        <General.PageTitle hasBackButton>
          <ComponentButtonBack />
          Vouchers do {locationState.eventName as string}
        </General.PageTitle>
        <EventName>
          <Small>Você está visualizando os seus vouchers do evento</Small>
          {locationState.eventName as string}
        </EventName>
      </General.PageTitleContainer>

      <ComponentIsVisible when={!errorMessage}>
        <Vouchers>
          <VoucherItemLoading show={isLoading} />

          <ComponentIsVisible when={!isLoading}>
            <ComponentIsVisible when={!!eventVouchers.length}>
              {eventVouchers.map(eventVoucher => (
                <VoucherItem
                  handleOpenDetails={() =>
                    handleOpenDetails({
                      eventId: Number(params.id),
                      id: eventVoucher.id,
                    })
                  }
                  key={eventVoucher.id}
                  voucher={eventVoucher}
                />
              ))}
            </ComponentIsVisible>
          </ComponentIsVisible>
        </Vouchers>
      </ComponentIsVisible>
      <ComponentIsVisible when={!!errorMessage}>
        <ComponentError message={errorMessage} onClick={loadEventVouchers} />
      </ComponentIsVisible>
    </Containers.Global>
  );
};

export default MyEventVouchers;
