import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IOrderState } from '../index';

export interface IDownloadTicketAction {
  data: {
    ourNumber: string;
    paymentId: number;
  };
  functions: {
    error(err: any): void;
    success(): void;
  };
}

function downloadTicket(
  _: Draft<IOrderState>,
  __: PayloadAction<IDownloadTicketAction>,
) {
  //
}

export default downloadTicket;
