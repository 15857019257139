import Tables from 'styles/tables';

import { InstallmentItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <InstallmentItemsHeader>
      <Tables.ItemHeader>Nº</Tables.ItemHeader>
      <Tables.ItemHeader>Nº do boleto</Tables.ItemHeader>
      <Tables.ItemHeader>Valor</Tables.ItemHeader>
      <Tables.ItemHeader>Venc.</Tables.ItemHeader>
      <Tables.ItemHeader>Data pgto.</Tables.ItemHeader>
      <Tables.ItemHeader>Status</Tables.ItemHeader>
      <Tables.ItemHeader center>Ações</Tables.ItemHeader>
    </InstallmentItemsHeader>
  );
};

export default ItemHeader;
