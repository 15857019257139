import { useCallback, useRef } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import helpers from 'helpers';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBack from 'components/button/Back';
import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import Containers from 'styles/containers';
import Forms from 'styles/forms';
import General from 'styles/general';

interface IChangePasswordFormData {
  confirmNewPassword: string;
  currentPassword: string;
  newPassword: string;
}

const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().trim().required('Informe a senha atual'),
  newPassword: Yup.string().trim().required('Informe a nova senha'),
  confirmNewPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], 'Senhas são diferentes')
    .required('Informe a confirmação da nova senha'),
});

const ChangePassword = (): JSX.Element => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(authSelectors.changePasswordIsLoading);

  const changePasswordFormRef = useRef<FormHandles>(null);

  const handleChangePassword: SubmitHandler<IChangePasswordFormData> =
    useCallback(
      async data => {
        try {
          changePasswordFormRef.current?.setErrors({});
          await changePasswordSchema.validate(data, {
            abortEarly: false,
          });

          reduxDispatch(
            authActions.changePasswordRequest({
              data: {
                currentPassword: data.currentPassword,
                newPassword: data.newPassword,
              },
              functions: {
                error(err: any) {
                  helpers.errorHandling(err);
                },
                success() {
                  changePasswordFormRef.current?.reset({});
                  toast.show({
                    title: 'Senha alterada com sucesso',
                    type: 'success',
                  });
                },
              },
            }),
          );
        } catch (err: any) {
          if (err instanceof Yup.ValidationError) {
            const validationErrors = helpers.getValidationErrors(err);
            changePasswordFormRef.current?.setErrors(validationErrors);
          }
        }
      },
      [reduxDispatch, toast],
    );

  return (
    <Containers.Global>
      <General.PageTitleContainer alignItems="flex-start" displayMobile="flex">
        <General.PageTitle hasBackButton>
          <ComponentButtonBack />
          Alterar senha
        </General.PageTitle>
      </General.PageTitleContainer>

      <Forms.Unform onSubmit={handleChangePassword} ref={changePasswordFormRef}>
        <Forms.Grid
          gridTemplateColumns="repeat(4, 12rem)"
          gridTemplateColumnsMobile="repeat(2, 1fr)"
        >
          <ComponentInputPassword label="Senha atual" name="currentPassword" />
          <ComponentInputPassword label="Nova senha" name="newPassword" />
          <ComponentInputPassword
            label="Confirmar nova senha"
            name="confirmNewPassword"
          />
          <ComponentButtonBase
            disabled={isLoading}
            isLoading={isLoading}
            type="submit"
            width="8rem"
          >
            Confirmar
          </ComponentButtonBase>
        </Forms.Grid>
      </Forms.Unform>
    </Containers.Global>
  );
};

export default ChangePassword;
