import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { EEventPaymentMethodType } from 'models/IEvent';

import { IPixState } from '../../index';

interface IAddress {
  city: string;
  complement?: string;
  neighborhood: string;
  number: string;
  postalCode: string;
  state: string;
  street: string;
}

interface IResponsible {
  document: string;
  phone: string;
  token: string;
}

interface IPixCreateSuccess {
  orderId: number;
  response: string;
  status: number;
}

export interface IPixCreateRequestAction {
  data: {
    address: IAddress;
    amount: number;
    eventId: number;
    installments: number;
    paymentMethodId: number;
    paymentMethodType: EEventPaymentMethodType;
    responsible: IResponsible;
  };
  functions: {
    error(err: any): void;
    success(data: IPixCreateSuccess): void;
  };
}

function createRequest(
  draft: Draft<IPixState>,
  _: PayloadAction<IPixCreateRequestAction>,
) {
  draft.create.config.isLoading = true;
}

export default createRequest;
