import getAddressFailure from './address/failure';
import getAddressRequest from './address/request';
import getAddressSuccess from './address/success';

const reducers = {
  getAddressRequest,
  getAddressSuccess,
  getAddressFailure,
};

export default reducers;
