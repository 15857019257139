import { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import pages from 'constants/pages';

import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';

import authSelectors from 'store/slices/auth/selectors';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import Forms from 'styles/forms';
import General from 'styles/general';

import { ContainerHeader, Info, Label, Subtitle, Value } from './styles';

const MyAccount = (): JSX.Element => {
  const navigate = useNavigate();
  const token = useReduxSelector(authSelectors.token);
  const name = useReduxSelector(authSelectors.name);
  const email = useReduxSelector(authSelectors.email);

  const handleNavigateTo = useCallback(
    (page: string) => {
      navigate(page);
    },
    [navigate],
  );

  useEffect(() => {
    if (!token) {
      navigate(pages.auth, { replace: true });
    }
  }, [navigate, token]);

  return (
    <Containers.Global>
      <General.PageTitleContainer alignItems="flex-start" displayMobile="flex">
        <ContainerHeader>
          <General.PageTitle>
            Minha conta
            <Subtitle>
              Confira ou edite seus dados abaixo. Veja também as suas reservas
              já realizadas.
            </Subtitle>
          </General.PageTitle>
          <ComponentButtonBase
            height="2.5rem"
            onClick={() => handleNavigateTo('/minha-conta/alterar-senha')}
            type="submit"
            width="10rem"
          >
            Alterar senha
          </ComponentButtonBase>
        </ContainerHeader>
      </General.PageTitleContainer>

      <Forms.Subtitle color={colors.orange700}>Dados pessoais</Forms.Subtitle>
      <Forms.Grid gridTemplateColumns="repeat(2, 18rem) ">
        <Info>
          <Label>Nome completo</Label>
          <Value>{name}</Value>
        </Info>
        <Info>
          <Label>E-mail</Label>
          <Value>{email}</Value>
        </Info>
      </Forms.Grid>
    </Containers.Global>
  );
};

export default MyAccount;
