import changePasswordIsLoading from './changePasswordIsLoading';
import email from './email';
import firstAccessIsLoading from './firstAccessIsLoading';
import forgotPasswordIsLoading from './forgotPasswordIsLoading';
import id from './id';
import name from './name';
import resetPasswordIsLoading from './resetPasswordIsLoading';
import signInIsLoading from './signInIsLoading';
import token from './token';

const authSelectors = {
  changePasswordIsLoading,
  email,
  firstAccessIsLoading,
  forgotPasswordIsLoading,
  id,
  name,
  resetPasswordIsLoading,
  signInIsLoading,
  token,
};

export default authSelectors;
