import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

function logoutRequest(draft: Draft<IAuthData>, _: PayloadAction) {
  draft.authorization.token = null;
  draft.profile.email = null;
  draft.profile.id = null;
  draft.profile.name = null;
}

export default logoutRequest;
