import amexImg from 'assets/brands/amex.png';
import eloImg from 'assets/brands/elo.png';
import mastercardImg from 'assets/brands/master.png';
import visaImg from 'assets/brands/visa.png';

interface ICardBrand {
  [key: string]: string;
}

const cardBrands: ICardBrand = {
  default: '',
  'american-express': amexImg,
  elo: eloImg,
  mastercard: mastercardImg,
  visa: visaImg,
};

const cardBrandsNames: ICardBrand = {
  'american-express': 'Amex',
  hipercard: 'Hipercard',
  elo: 'Elo',
  mastercard: 'Mastercard',
  visa: 'Visa',
};

export { cardBrandsNames };
export default cardBrands;
