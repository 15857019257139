import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostChangePasswordRequest from 'services/httpClient/request/auth/IPostChangePasswordRequest';

import { authActions } from '../index';
import { IChangePasswordRequestAction } from '../reducers/changePassword/request';

function* changePassword(action: PayloadAction<IChangePasswordRequestAction>) {
  try {
    const body: IPostChangePasswordRequest = {
      currentPassword: action.payload.data.currentPassword.trim(),
      newPassword: action.payload.data.newPassword.trim(),
    };

    yield call(
      httpClient.post,
      'sessions/institution-customers/change-password',
      body,
    );

    yield put(authActions.changePasswordSuccess());
    action.payload.functions.success();
  } catch (err: any) {
    action.payload.functions.error(err);
    yield put(authActions.changePasswordFailure());
  }
}

export default changePassword;
