import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IEventData } from '../../index';

export interface IGetEventInfoRequestAction {
  data: {
    eventId: number;
    responsibleToken: string;
  };
  functions: {
    alreadyBought(): void;
    notFound: () => void;
  };
}

function getInfoRequest(
  draft: Draft<IEventData>,
  _: PayloadAction<IGetEventInfoRequestAction>,
) {
  draft.getInfo.config.isLoading = true;
  draft.getInfo.data = null;
}

export default getInfoRequest;
