import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';

import { authActions } from 'store/slices/auth';

import { Details, Header, HeaderBackground, Label } from './styles';

const ComponentMenu = (): JSX.Element => {
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();

  const handleNavigateTo = useCallback(
    (page: string) => {
      navigate(page);
    },
    [navigate],
  );

  const handleLogout = useCallback(() => {
    reduxDispatch(authActions.logoutRequest());
    navigate(pages.auth, { replace: true });
  }, [reduxDispatch, navigate]);

  return (
    <HeaderBackground>
      <Header>
        <Label onClick={() => handleNavigateTo(pages.myEvent.list)}>
          Meus eventos
        </Label>
        <Label onClick={() => handleNavigateTo(pages.myAccount)}>
          Minha Conta
        </Label>
      </Header>
      <Details>
        <Label onClick={handleLogout}>Sair</Label>
      </Details>
    </HeaderBackground>
  );
};

export default ComponentMenu;
