import styled from 'styled-components';

import Tables from 'styles/tables';

export const MyEventsItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 4rem 30rem 1fr 7rem;
  padding: 1.2rem 1rem;
`;

export const MyEventsItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 4rem 30rem 1fr 7rem;
  padding: 1rem;
`;
