import styled, { css } from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Subtitle = styled.small`
  color: ${colors.gray200};
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.25rem;
`;

export const ContainerHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LogoutButton = styled.button`
  align-items: flex-start;
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.red700};
  column-gap: 0.5rem;
  display: flex;
  font-size: 0.875rem;
  height: auto;
  margin-left: auto;

  &:hover {
    opacity: 0.75;
  }
  @media screen and (min-width: 768px) {
    align-items: center;
  }
`;

export const Info = styled.div`
  display: grid;
  margin-top: 0.5rem;
  row-gap: 0.25rem;
`;

export const Label = styled.label`
  font-size: 0.75rem;
`;

interface IValueProps {
  isLoading?: boolean;
}

export const Value = styled.p<IValueProps>`
  color: ${colors.darkBlue900};
  font-size: 0.875rem;
  font-weight: 600;

  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 0.875rem;
      width: 100%;
    `};
`;
