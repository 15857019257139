import { useRef } from 'react';

import formatters from 'formatters';

import ComponentInputBase, {
  IComponentInputBaseProps,
  IComponentInputBaseRefProps,
} from 'components/input/Base';

type IComponentInputCpfProps = IComponentInputBaseProps;

const ComponentInputCpf: React.FC<IComponentInputCpfProps> = props => {
  const componentInputBaseRef = useRef<IComponentInputBaseRefProps>(null);

  const handleOnChangeCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const textFormatted = formatters.cpf(text);

    componentInputBaseRef.current?.setValueRef(textFormatted);
  };

  return (
    <ComponentInputBase
      {...props}
      maxLength={18}
      onChange={handleOnChangeCpf}
      ref={componentInputBaseRef}
    />
  );
};

export default ComponentInputCpf;
