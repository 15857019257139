import { BrowserRouter } from 'react-router-dom';

import AppProvider from 'hooks';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentMenu from 'components/Menu';
import ComponentIsVisible from 'components/utils/IsVisible';

import Routes from 'routes';
import ErrorBoundary from 'routes/ErrorBoundary';

import authSelectors from 'store/slices/auth/selectors';

import Containers from 'styles/containers';
import GlobalStyle from 'styles/global';

const App: React.FC = () => {
  const token = useReduxSelector(authSelectors.token);

  return (
    <AppProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <Containers.Root>
            <ComponentIsVisible when={!!token}>
              <ComponentMenu />
            </ComponentIsVisible>
            <Routes />
          </Containers.Root>
        </BrowserRouter>
      </ErrorBoundary>

      <GlobalStyle />
    </AppProvider>
  );
};

export default App;
