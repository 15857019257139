import { authReducers, IAuthData } from './slices/auth';
import { customerReducers, ICustomerData } from './slices/customer';
import { eventReducers, IEventData } from './slices/event';
import { IOrderState, orderReducers } from './slices/order';
import { IPixState, pixReducers } from './slices/pix';
import { IPostalCodeData, postalCodeReducers } from './slices/postalCode';

export interface IApplicationState {
  auth: IAuthData;
  customer: ICustomerData;
  event: IEventData;
  order: IOrderState;
  pix: IPixState;
  postalCode: IPostalCodeData;
}

const rootReducers = {
  auth: authReducers,
  customer: customerReducers,
  event: eventReducers,
  order: orderReducers,
  pix: pixReducers,
  postalCode: postalCodeReducers,
};

export default rootReducers;
