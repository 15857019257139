import React from 'react';

import General from 'styles/general';

import { Bold, Container, ListText, ListTitle, Text, Title } from './styles';

const LgpdPolitic: React.FC = () => {
  return (
    <Container>
      <General.PageTitleContainer>
        <Title>Política de Lei Geral de Proteção de Dados</Title>
      </General.PageTitleContainer>
      <Text>
        Esta POLÍTICA INTERNA DE PROTEÇÃO DE DADOS contém as normas internas que
        regem a gestão de dados entre MSL Eventos e Produções Ltda. - denominada
        CONTROLADORA e seus clientes, consumidores, colaboradores, prestadores
        de serviços, terceiros que com esta se relacionam – denominados
        USUÁRIOS, de forma que, tendo assinado o TERMO DE CONSENTIMENTO DE USO
        DE DADOS, o interessado estará ciente das regras abaixo dispostas.
      </Text>
      <Text>
        A presente política regula o tratamento de dados fornecidos pelos
        USUÁRIOS em razão da relação havida com a empresa CONTROLADORA.
      </Text>
      <Text>
        Para fins desta política,{' '}
        <Bold>
          considera-se USUÁRIO todo aquele que, de alguma forma, mantém vínculo
          de relação com a CONTROLADORA, seja de natureza comercial,
          profissional, laboral, ou outra qualquer.
        </Bold>
      </Text>
      <Text>
        Ao aceitar o TERMO DE CONSENTIMENTO DE UTILIZAÇÃO DE DADOS o USUÁRIO
        adere integralmente ao presente instrumento (POLÍTICA INTERNA DE
        PROTEÇÃO DE DADOS), entendendo e aceitando todas as condições
        estabelecidas no termo de consentimento e nesta política.
      </Text>

      <ListTitle marginTop>
        Para fins desta política e em consonância com o disposto na Lei Geral de
        Proteção de Dados (Lei nº 13.709, de 14 de agosto de 2018),
        considerar-se-á:
      </ListTitle>
      <ListText>
        1. BANCO DE DADOS: conjunto estruturado de dados pessoais, estabelecido
        em um ou em vários locais, em suporte eletrônico ou físico;
      </ListText>
      <ListText>
        2. TITULAR: pessoa natural a quem se referem os dados pessoais que são
        objeto de tratamento;
      </ListText>
      <ListText>
        3. CONTROLADOR: pessoa natural ou jurídica, de direito público ou
        privado, a quem competem as decisões referentes ao tratamento de dados
        pessoais;
      </ListText>
      <ListText>
        4. OPERADOR: pessoa natural ou jurídica, de direito público ou privado,
        que realiza o tratamento de dados pessoais em nome do controlador;
      </ListText>
      <ListText>
        5. DADO PESSOAL: informação relacionada a pessoa natural identificada ou
        identificável;
      </ListText>
      <ListText>
        6. DADO ANONIMIZADO: dado relativo a titular que não possa ser
        identificado, considerando a utilização de meios técnicos razoáveis e
        disponíveis na ocasião de seu tratamento;
      </ListText>
      <ListText>
        7. TRATAMENTO: toda operação realizada com dados pessoais, como as que
        se referem a coleta, produção, recepção, classificação, utilização,
        acesso, reprodução, transmissão, distribuição, processamento,
        arquivamento, armazenamento, eliminação, avaliação ou controle da
        informação, modificação, comunicação, transferência, difusão ou
        extração;
      </ListText>
      <ListText>
        8. ANONIMIZAÇÃO: utilização de meios técnicos razoáveis e disponíveis no
        momento do tratamento, por meio dos quais um dado perde a possibilidade
        de associação, direta ou indireta, a um indivíduo;
      </ListText>
      <ListText>
        9. CONSENTIMENTO: manifestação livre, informada e inequívoca pela qual o
        titular concorda com o tratamento de seus dados pessoais para uma
        finalidade determinada;
      </ListText>
      <ListText>
        10. FINALIDADE: realização do tratamento para propósitos legítimos,
        específicos, explícitos e informados ao titular, sem possibilidade de
        tratamento posterior de forma incompatível com essas finalidades;
      </ListText>
      <ListText>
        11. BLOQUEIO: suspensão temporária de qualquer operação de tratamento,
        mediante guarda do dado pessoal ou do banco de dados;
      </ListText>
      <ListText>
        12. ELIMINAÇÃO: exclusão de dado ou de conjunto de dados armazenados em
        banco de dados, independentemente do procedimento empregado;
      </ListText>
      <ListText>
        13. AUTORIDADE NACIONAL: órgão da administração pública indireta
        responsável por zelar, implementar e fiscalizar o cumprimento da Lei
        Geral de Proteção de Dados (Lei nº 13.709, de 14 de agosto de 2018), se
        houver.
      </ListText>

      <ListTitle marginTop>
        <Bold>DO TRATAMENTO DOS DADOS E DAS SUAS FINALIDADES</Bold>
      </ListTitle>
      <Text>
        A CONTROLADORA poderá coletar todos os DADOS PESSOAIS fornecidos pelo
        USUÁRIO.
      </Text>
      <Text>
        O USUÁRIO está ciente que o não fornecimento de alguns dados pode obstar
        seu acesso a todos os (serviços/produtos/conteúdos) disponibilizados
        pela CONTROLADORA.
      </Text>
      <Text>
        A CONTROLADORA poderá, automaticamente, coletar algumas informações do
        USUÁRIO quando este acessa e utiliza o sítio eletrônico, tais como
        características do dispositivo de acesso, do navegador, protocolo de
        internet (IP com data, hora e origem), informações sobre cliques,
        páginas acessadas, buscas realizadas no sítio eletrônico e cookies.
      </Text>
      <Text>
        Os DADOS PESSOAIS coletados servirão para que a CONTROLADORA possa
        prestar os serviços ou à venda de produtos a que se propõe.
      </Text>

      <Text>
        Os DADOS PESSOAIS também poderão ser utilizados pelo CONTROLADOR(A) para
        envio de comunicações, notícias, propagandas e mensagens promocionais,
        bem como para fins publicitários e estatísticos.
      </Text>
      <Text>
        Após a ANONIMIZAÇÃO dos DADOS PESSOAIS do USUÁRIO, o CONTROLADOR(A)
        poderá elaborar estudos e pesquisas de caráter histórico, científico,
        tecnológico ou estatístico de seu interesse.
      </Text>
      <Text>
        O CONTROLADOR(A) poderá compartilhar os DADOS PESSOAIS do USUÁRIO com
        terceiros, sejam eles entidades públicas ou privadas, que poderão
        utilizá-los exclusivamente para os mesmos fins descritos nesta política,
        ainda que com intuito lucrativo.
      </Text>
      <Text>
        A CONTROLADORA assegura ao USUÁRIO, mediante requerimento, o direito de
        informação de quais entidades públicas ou privadas foram beneficiadas
        pelo compartilhamento dos dados a que se refere esta cláusula.
      </Text>
      <Text>
        Os DADOS PESSOAIS do USUÁRIO serão tratados pelo período de 5 (cinco)
        anos, com sua posterior eliminação, sendo autorizada sua conservação nas
        hipóteses descritas no artigo 16 da Lei Geral de Proteção de Dados (Lei
        nº 13.709, de 14 de agosto de 2018).
      </Text>
      <Text>
        O TRATAMENTO dos DADOS PESSOAIS pela CONTROLADOR(A) ou por terceiro por
        ele autorizado será realizado em conformidade com a legislação de
        regência, em especial a Lei Geral de Proteção de Dados (Lei nº 13.709,
        de 14 de agosto de 2018).
      </Text>
      <Text>
        As partes declaram ciência de que os dados fornecidos pelo USUÁRIO, uma
        vez anonimizados, não são considerados DADOS PESSOAIS, como estabelece o
        artigo 12 da Lei Geral de Proteção de Dados (Lei nº 13.709, de 14 de
        agosto de 2018).
      </Text>
      <Text>
        Se o USUÁRIO não desejar receber e-mails promocionais, gerais ou
        personalizados, enviados pelo CONTROLADOR(A) em razão dos dados
        coletados através desta política, deverá seguir o procedimento de
        descredenciamento presente normalmente ao final do correio eletrônico
        recebido.
      </Text>
      <Text>
        O USUÁRIO poderá contatar o CONTROLADOR(A) a qualquer momento, através
        dos contatos referidos no parágrafo antecedente, para exercer os
        direitos previstos na legislação vigente e nesta política.
      </Text>
      <Text>
        O CONTROLADOR(A) indicará pessoa natural para atuar como encarregado da
        proteção de dados, o qual será responsável por atuar como canal de
        comunicação entre o CONTROLADOR(A) (na qualidade de CONTROLADOR) e o
        USUÁRIO e entre o CONTROLADOR(A) (na qualidade de CONTROLADOR) e a
        AUTORIDADE NACIONAL, se houver.
      </Text>
      <Text>
        O CONTROLADOR(A) manterá registro das operações de TRATAMENTO de DADOS
        PESSOAIS que realizar.
      </Text>

      <ListTitle marginTop>
        Na forma do artigo 18 da Lei Geral de Proteção de Dados (Lei nº 13.709,
        de 14 de agosto de 2018), o USUÁRIO, na qualidade de TITULAR de DADOS
        PESSOAIS, tem direito a obter do CONTROLADOR(A), em relação aos dados
        tratados, a qualquer momento e mediante requisição, a:
      </ListTitle>

      <ListText>1. Confirmação da existência de TRATAMENTO;</ListText>
      <ListText>2. Acesso aos dados;</ListText>
      <ListText>
        3. Correção de dados incompletos, inexatos ou desatualizados;
      </ListText>
      <ListText>
        4. ANONIMIZAÇÃO, BLOQUEIO ou ELIMINAÇÃO de dados desnecessários,
        excessivos ou tratados em desconformidade com a LGPD ou nesta política
      </ListText>
      <ListText>
        5. Portabilidade dos dados a outro fornecedor de serviço ou produto,
        mediante requisição expressa e observados os segredos comercial e
        industrial, de acordo com a regulamentação legal e com exceção dos dados
        que já tenham sido anonimizados pelo CONTROLADOR(A);
      </ListText>
      <ListText>
        6. Eliminação dos DADOS PESSOAIS tratados com o consentimento do
        TITULAR, exceto nas hipóteses previstas no artigo 16 da Lei Geral de
        Proteção de Dados (Lei nº 13.709, de 14 de agosto de 2018);
      </ListText>
      <ListText>
        7. Informação das entidades públicas e privadas com as quais o
        CONTROLADOR(A) realizou uso compartilhado de dados;
      </ListText>
      <ListText>
        8. Informação sobre a possibilidade de não fornecer consentimento e
        sobre as consequências da negativa, dentre as quais destaca-se a
        impossibilidade de acesso a algumas funcionalidades ou conteúdos do
        sítio eletrônico; e,
      </ListText>
      <ListText>
        9. Revogação do consentimento, nos termos do § 5º do art. 8º da Lei
        Geral de Proteção de Dados (Lei nº 13.709, de 14 de agosto de 2018).
      </ListText>

      <Text>
        O USUÁRIO tem o direito de peticionar em relação aos seus dados contra o
        CONTROLADOR(A) perante a AUTORIDADE NACIONAL, se houver, e utilizar dos
        meios legais para fazer cumprir o presente instrumento e a legislação de
        regência.
      </Text>

      <Text>
        O USUÁRIO pode opor-se a tratamento realizado em desacordo com a Lei
        Geral de Proteção de Dados (Lei nº 13.709, de 14 de agosto de 2018) ou
        com a presente política.
      </Text>

      <Text>
        Os direitos previstos nesta cláusula serão exercidos mediante
        requerimento expresso do USUÁRIO, ou de representante legalmente
        constituído, ao CONTROLADOR(A).
      </Text>

      <Text>
        Na hipótese em que o requerimento objetivar a correção, ELIMINAÇÃO,
        ANONIMIZAÇÃO ou BLOQUEIO dos dados, caso estes tenham sido
        compartilhados com terceiro, o CONTROLADOR(A) informará imediatamente o
        beneficiário do compartilhamento para que igualmente atenda à
        solicitação do USUÁRIO.
      </Text>

      <Text>
        Nos termos do § 4º do art. 18 da Lei Geral de Proteção de Dados (Lei nº
        13.709 de 14 de agosto de 2018), em caso de impossibilidade de adoção
        imediata das providências de que trata o item anterior, o CONTROLADOR(A)
        enviará ao USUÁRIO resposta em que poderá (i) comunicar que não é agente
        de tratamento (CONTROLADOR ou OPERADOR) dos dados e indicar, sempre que
        possível, o real responsável; ou, (ii) indicar as razões de fato ou de
        direito que impedem a adoção imediata da providência.
      </Text>

      <ListTitle marginTop>
        <Bold>DA SEGURANÇA</Bold>
      </ListTitle>

      <Text>
        O CONTROLADOR(A) adotará medidas de segurança, técnicas e
        administrativas, aptas a proteger os DADOS PESSOAIS de acessos não
        autorizados e de situações acidentais ou ilícitas de destruição, perda,
        alteração, comunicação ou qualquer forma de tratamento inadequado ou
        ilícito.
      </Text>

      <Text>
        O CONTROLADOR(A) comunicará à AUTORIDADE NACIONAL, se houver, e ao
        USUÁRIO a ocorrência de incidente de segurança que possa acarretar risco
        ou dano relevante a partir do uso indevido dos DADOS PESSOAIS, nos
        termos da Lei Geral de Proteção de Dados (Lei nº 13.709, de 14 de agosto
        de 2018).
      </Text>
      <ListTitle marginTop>
        <Bold>DAS DISPOSIÇÕES FINAIS</Bold>
      </ListTitle>

      <Text>
        Ao aceitar o termo de consentimento solicitado pelo CONTROLADOR, o
        USUÁRIO confirma a veracidade dos dados informados no momento do
        cadastro, sob pena de responsabilização civil e penal.
      </Text>

      <Text>
        O CONTROLADOR(A) se reserva no direito de modificar o presente
        instrumento em qualquer de suas disposições, inclusive sem prévia
        notificação, exceto nos casos exigidos pela legislação.
      </Text>

      <Text>
        As informações e direitos decorrentes deste instrumento poderão ser
        transferidos a terceiros em decorrência da venda, aquisição, fusão,
        reorganização societária ou qualquer outra mudança no controle do
        CONTROLADOR(A), ocasião em que o USUÁRIO será notificado para que
        manifeste sua concordância, ainda que tacitamente.
      </Text>

      <Text>
        Serão consideradas válidas, para todos os efeitos, as comunicações
        realizadas pelo CONTROLADOR(A) através dos dados cadastrais informados
        pelo USUÁRIO, competindo a este proceder com eventuais atualizações nos
        seus dados cadastrais.
      </Text>

      <Text>
        Qualquer falha do CONTROLADOR(A) para impor ou exercer qualquer
        disposição deste instrumento ou direitos conexos, não constitui uma
        renúncia a esse direito ou disposição.
      </Text>

      <Text>
        A presente política vigorará enquanto perdurarem os efeitos resultantes
        da sua adesão.
      </Text>
    </Container>
  );
};

export default LgpdPolitic;
