import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAddressResponse from 'services/httpClient/responses/address/IGetAddressResponse';

import { postalCodeActions } from '../index';
import { IGetAddressRequestAction } from '../reducers/address/request';

function* getAddress(action: PayloadAction<IGetAddressRequestAction>) {
  try {
    const response: AxiosResponse<IGetAddressResponse> = yield call(
      httpClient.get,
      `addresses/postal-codes/${action.payload.data.postalCode}`,
    );

    yield put(postalCodeActions.getAddressSuccess());
    action.payload.functions.setFields(response.data.info);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(postalCodeActions.getAddressFailure());
  }
}

export default getAddress;
