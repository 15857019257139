interface ICardNumberOptions {
  defaultValue?: string;
}

function cardNumber(value: string, options?: ICardNumberOptions): string {
  if (!value) {
    return options?.defaultValue || '';
  }
  const cardNumberFormatted = value
    .replace(/[^0-9]/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{6})(\d)/, '$1 $2')
    .replace(/(\d{4}\s\d{6})(\d{5})(\d)/, '$1 $2 $3')
    .replace(/(\d{4})\s(\d{4})(\d{2})\s(\d{2})(\d{3})(\d)/, '$1 $2 $3$4 $5$6')
    .replace(/((\d{4}\s\d{6}\s\d{5})|(\d{4}\s\d{4}\s\d{4}))\d+?$/, '$1');
  return cardNumberFormatted;
}

export default cardNumber;
