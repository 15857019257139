import { useNavigate } from 'react-router-dom';

import { FiChevronLeft } from 'react-icons/fi';

import { Container } from './styles';

interface IComponentButtonBack {
  color?: string;
}

const ComponentButtonBack = ({ color }: IComponentButtonBack): JSX.Element => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container color={color} onClick={handleGoBack}>
      <FiChevronLeft size={26} />
    </Container>
  );
};

ComponentButtonBack.defaultProps = {
  color: undefined,
};

export default ComponentButtonBack;
