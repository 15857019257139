import { createSlice } from '@reduxjs/toolkit';
import ICustomerEvent from 'models/ICustomerEvent';
import ICustomerEventVoucher from 'models/ICustomerEventVoucher';
import ICustomerEventVoucherDetails from 'models/ICustomerEventVoucherDetails';

import reducers from './reducers';

export interface ICustomerData {
  getAllEvent: {
    config: {
      emptyMessage: string;
      errorMessage: string;
      isLoading: boolean;
    };
    list: ICustomerEvent[];
  };
  getAllEventVoucher: {
    config: {
      errorMessage: string;
      isLoading: boolean;
    };
    list: ICustomerEventVoucher[];
  };
  getEventVoucherDetails: {
    config: {
      isLoading: boolean;
    };
    details: ICustomerEventVoucherDetails | null;
  };
}

const initialState: ICustomerData = {
  getAllEvent: {
    config: {
      isLoading: false,
      emptyMessage: 'Não há eventos a serem exibidos',
      errorMessage: '',
    },
    list: [],
  },
  getAllEventVoucher: {
    config: {
      isLoading: false,
      errorMessage: '',
    },
    list: [],
  },
  getEventVoucherDetails: {
    config: {
      isLoading: false,
    },
    details: null,
  },
};

const customerSlice = createSlice({
  name: '@customer',
  initialState,
  reducers,
});

export const customerActions = customerSlice.actions;
export const customerReducers = customerSlice.reducer;
