import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetCustomerEventVoucherResponse from 'services/httpClient/responses/customer/IGetAllCustomerEventVoucherResponse';

import { customerActions } from '../index';
import { IGetAllCustomerVouchersRequestAction } from '../reducers/getAllEventVoucher/request';

function* getAllEventVoucher(
  action: PayloadAction<IGetAllCustomerVouchersRequestAction>,
) {
  try {
    const { eventId } = action.payload.data;
    const response: AxiosResponse<IGetCustomerEventVoucherResponse> =
      yield call(httpClient.get, `responsibles/events/${eventId}/tickets`);

    yield put(
      customerActions.getAllEventVoucherSuccess({
        list: response.data.list,
      }),
    );
  } catch (err: any) {
    yield put(customerActions.getAllEventVoucherFailure());
    action.payload.functions.error(err);
  }
}

export default getAllEventVoucher;
