import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface IPixState {
  create: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IPixState = {
  create: {
    config: {
      isLoading: false,
    },
  },
};

const pixSlice = createSlice({
  name: '@pix',
  initialState,
  reducers,
});

export const pixActions = pixSlice.actions;
export const pixReducers = pixSlice.reducer;
