import { all, takeLatest } from 'redux-saga/effects';

import { postalCodeActions } from '../index';
import getAddress from './getAddress';

const postalCodeSagas = all([
  takeLatest(postalCodeActions.getAddressRequest, getAddress),
]);

export default postalCodeSagas;
