import getInfoFailure from './getInfo/failure';
import getInfoRequest from './getInfo/request';
import getInfoSuccess from './getInfo/success';

const reducers = {
  getInfoFailure,
  getInfoRequest,
  getInfoSuccess,
};

export default reducers;
