import styled from 'styled-components';

import Tables from 'styles/tables';

export const InstallmentItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 6.5rem repeat(2, 1fr) repeat(3, 9rem) 7rem;
  padding: 1.25rem 1.25rem;
`;

export const InstallmentItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 6.5rem repeat(2, 1fr) repeat(3, 9rem) 7rem;
  padding: 1rem 1.25rem;
`;
