import { all, takeLatest } from 'redux-saga/effects';

import { orderActions } from '../index';
import downloadTicket from './downloadTicket';
import purchase from './purchase';

const orderSagas = all([
  takeLatest(orderActions.downloadTicket, downloadTicket),
  takeLatest(orderActions.purchaseRequest, purchase),
]);

export default orderSagas;
