import { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateY(15rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const disappear = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(15rem);
  }
`;

const leftAppear = keyframes`
  from {
    opacity: 0;
    transform: translateX(-5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const leftAppearModal = keyframes`
  from {
    opacity: 0;
    transform: translateX(50rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
`;
const rightDisappearModal = keyframes`
  from {
    opacity: 1;
    transform: translateX(0rem);
  }
  to {
    opacity: 0;
    transform: translateX(50rem);
  }
`;

const rightMenuIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(18rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
`;

const rightMenuOut = keyframes`
from {
  opacity: 1;
  transform: translateX(0rem);
}
to {
  opacity: 0;
  transform: translateX(18rem);
}
`;

const scaleIn = keyframes`
  0% {
    transform: scale(.6);
    opacity: .4;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const shimmer = keyframes`
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
`;

export default {
  appear,
  disappear,
  leftAppear,
  leftAppearModal,
  rightDisappearModal,
  rightMenuIn,
  rightMenuOut,
  scaleIn,
  shimmer,
};
