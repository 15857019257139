import { useRef } from 'react';

import formatters from 'formatters';
import helpers from 'helpers';
import IAddress from 'models/IAddress';

import { useReduxDispatch } from 'hooks/useReduxDispatch';

import ComponentInputBase, {
  IComponentInputBaseProps,
  IComponentInputBaseRefProps,
} from 'components/input/Base';

import { postalCodeActions } from 'store/slices/postalCode';

interface IComponentInputPostalCodeProps extends IComponentInputBaseProps {
  onSetFieldsAddress?(address: IAddress): void;
}

const ComponentInputPostalCode: React.FC<IComponentInputPostalCodeProps> = ({
  onSetFieldsAddress,
  ...props
}) => {
  const reduxDispatch = useReduxDispatch();

  const componentInputBaseRef = useRef<IComponentInputBaseRefProps>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const textFormatted = formatters.postalCode(text);

    if (onSetFieldsAddress) {
      onSetFieldsAddress({
        city: '',
        neighborhood: '',
        postalCode: '',
        state: '',
        street: '',
      });
    }

    if (textFormatted.length === 9 && onSetFieldsAddress) {
      onSetFieldsAddress({
        city: 'BUSCANDO',
        neighborhood: 'BUSCANDO',
        postalCode: 'BUSCANDO',
        state: '...',
        street: 'BUSCANDO',
      });
      reduxDispatch(
        postalCodeActions.getAddressRequest({
          data: {
            postalCode: textFormatted,
          },
          functions: {
            error(err: any) {
              onSetFieldsAddress({
                city: '',
                neighborhood: '',
                postalCode: '',
                state: '',
                street: '',
              });
              helpers.errorHandling(err);
            },
            setFields(address: IAddress) {
              onSetFieldsAddress(address);
            },
          },
        }),
      );
    }

    componentInputBaseRef.current?.setValueRef(textFormatted);
  };

  return (
    <ComponentInputBase
      {...props}
      label="CEP"
      maxLength={9}
      onChange={handleOnChange}
      ref={componentInputBaseRef}
    />
  );
};

export default ComponentInputPostalCode;
