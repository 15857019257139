import { all } from 'redux-saga/effects';

import authSagas from './slices/auth/sagas';
import customerSagas from './slices/customer/sagas';
import eventSagas from './slices/event/sagas';
import orderSagas from './slices/order/sagas';
import pixSagas from './slices/pix/sagas';
import postalCodeSagas from './slices/postalCode/sagas';

function* rootSagas() {
  yield all([
    authSagas,
    customerSagas,
    eventSagas,
    orderSagas,
    postalCodeSagas,
    pixSagas,
  ]);
}

export default rootSagas;
