import { useState } from 'react';

import { FiDownload } from 'react-icons/fi';
import { PuffLoader } from 'react-spinners';

import ETicketStatus from 'enums/ticketStatus';
import helpers from 'helpers';

import { useReduxDispatch } from 'hooks/useReduxDispatch';

import ComponentIsVisible from 'components/utils/IsVisible';

import { orderActions } from 'store/slices/order';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { InstallmentItemsBody } from '../styles';

interface IVoucherDetailsOrderPayment {
  amount: string;
  dueDate: string;
  id: number;
  installmentNumber: number;
  ourNumber: string;
  paidDate: string | null;
  status: string;
  url: string;
}

interface IItemBodyProps {
  length: number;
  payment: IVoucherDetailsOrderPayment;
  position: number;
}

const IconDownloadTicket = ({
  color,
  isLoading,
}: {
  color: string;
  isLoading: boolean;
}): JSX.Element => {
  if (isLoading) {
    return (
      <PuffLoader
        color={color || colors.white900}
        size={24}
        speedMultiplier={2}
      />
    );
  }
  return <FiDownload color={color} size={18} />;
};

const ItemBody = ({
  length,
  payment,
  position,
}: IItemBodyProps): JSX.Element => {
  const reduxDispatch = useReduxDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownloadTicket = (): void => {
    setIsLoading(true);
    reduxDispatch(
      orderActions.downloadTicket({
        data: {
          ourNumber: payment.ourNumber || '-',
          paymentId: payment.id,
        },
        functions: {
          error: (err: any): void => {
            helpers.errorHandling(err);
            setIsLoading(false);
          },
          success: (): void => {
            setIsLoading(false);
          },
        },
      }),
    );
  };

  return (
    <InstallmentItemsBody addColorRow={!!(position % 2 === 0)}>
      <Tables.ItemBody>{`${payment.installmentNumber}/${length}`}</Tables.ItemBody>
      <Tables.ItemBody>{payment.ourNumber || '-'}</Tables.ItemBody>
      <Tables.ItemBody bold>{`R$ ${payment.amount}`}</Tables.ItemBody>
      <Tables.ItemBody>{payment.dueDate}</Tables.ItemBody>
      <Tables.ItemBody>{payment.paidDate}</Tables.ItemBody>
      <Tables.ItemBody>
        <ComponentIsVisible when={payment.status === ETicketStatus.Opened}>
          Em aberto
        </ComponentIsVisible>
        <ComponentIsVisible when={payment.status === ETicketStatus.Expired}>
          Vencido
        </ComponentIsVisible>
        <ComponentIsVisible
          when={payment.status === ETicketStatus.WaitingPayment}
        >
          Aguardando pagamento
        </ComponentIsVisible>
        <ComponentIsVisible
          when={payment.status === ETicketStatus.WaitingRegister}
        >
          Aguardando registro
        </ComponentIsVisible>
        <ComponentIsVisible when={payment.status === ETicketStatus.Paid}>
          Pago
        </ComponentIsVisible>
      </Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <Tables.ItemAction
            disabled={isLoading || !payment.ourNumber}
            onClick={handleDownloadTicket}
            title="Baixar boleto"
            type="button"
          >
            <IconDownloadTicket
              color={payment.ourNumber ? colors.orange700 : colors.gray500}
              isLoading={isLoading}
            />
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </InstallmentItemsBody>
  );
};

export default ItemBody;
