import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IPixState } from '../../index';

interface ICheckIsPaidRequestAction {
  data: {
    eventId: number;
    orderId: number;
    paymentId: string;
    responsibleToken: string;
  };
  functions: {
    success: () => void;
  };
}

function checkIsPaidRequest(
  _: Draft<IPixState>,
  __: PayloadAction<ICheckIsPaidRequestAction>,
) {
  //
}

export type { ICheckIsPaidRequestAction };
export default checkIsPaidRequest;
