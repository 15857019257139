import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import formatters from 'formatters';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostPixRequest from 'services/httpClient/request/pix/IPostPixRequest';
import IPostOrderResponse from 'services/httpClient/responses/order/IPostOrderResponse';

import { pixActions } from '../index';
import { IPixCreateRequestAction } from '../reducers/create/request';

function* create(action: PayloadAction<IPixCreateRequestAction>) {
  try {
    const body: IPostPixRequest = {
      address: {
        city: action.payload.data.address.city,
        neighborhood: action.payload.data.address.neighborhood,
        number: action.payload.data.address.number,
        postalCode: formatters.removeMask(
          action.payload.data.address.postalCode,
        ),
        state: action.payload.data.address.state,
        street: action.payload.data.address.street,
        complement: action.payload.data.address.complement || undefined,
      },
      responsible: {
        document: formatters.removeMask(
          action.payload.data.responsible.document,
        ),
        phone: formatters.removeMask(action.payload.data.responsible.phone),
        token: action.payload.data.responsible.token,
      },
      amount: action.payload.data.amount * 100,
      eventId: action.payload.data.eventId,
      eventPaymentMethodId: action.payload.data.paymentMethodId,
      paymentMethodType: action.payload.data.paymentMethodType,
      installments: action.payload.data.installments,
    };
    const response: AxiosResponse<IPostOrderResponse> = yield call(
      httpClient.post,
      'orders/privates',
      body,
    );
    yield put(pixActions.createSuccess());
    action.payload.functions.success({
      response: response.data.response as string,
      status: response.data.status as number,
      orderId: response.data.orderId as number,
    });
  } catch (err: any) {
    yield put(pixActions.createFailure());
    action.payload.functions.error(err);
  }
}

export default create;
