import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IGetAllCustomerEventListResponse } from 'services/httpClient/responses/customer/IGetAllCustomerEventResponse';

import { ICustomerData } from '../../index';

interface IGetAllCustomerEventSuccessWithDataAction {
  list: IGetAllCustomerEventListResponse[];
}

function getAllEventSuccess(
  draft: Draft<ICustomerData>,
  action: PayloadAction<IGetAllCustomerEventSuccessWithDataAction>,
) {
  draft.getAllEvent.config.isLoading = false;
  draft.getAllEvent.list = action.payload.list.map(customerEvent => ({
    date: {
      end: formatters.date(new Date(customerEvent.date.end), 'dd/MM/yyyy'),
      start: formatters.date(new Date(customerEvent.date.start), 'dd/MM/yyyy'),
    },
    id: customerEvent.id,
    name: customerEvent.name,
  }));
}

export default getAllEventSuccess;
