import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import helpers from 'helpers';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputSimple from 'components/input/Simple';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import Forms from 'styles/forms';

import { Actions, Message } from './styles';

export interface IForgotPasswordRefProps {
  close(): void;
  open(): void;
}

interface IForgotPasswordFormData {
  email: string;
}

const forgotPasswordSchemaValidation = Yup.object().shape({
  email: Yup.string()
    .email('Informe um e-mail válido (ex: email@email.com)')
    .required('Informe o e-mail'),
});

const ForgotPassword: React.ForwardRefRenderFunction<
  IForgotPasswordRefProps
> = (_, ref) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();
  const isLoading = useReduxSelector(authSelectors.forgotPasswordIsLoading);
  const [hideModal, setHideModal] = useState<boolean>(false);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const forgotPasswordFormRef = useRef<FormHandles>(null);

  const openModal = useCallback(() => {
    setHideModal(false);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleForgotPassword: SubmitHandler<IForgotPasswordFormData> =
    useCallback(
      async data => {
        try {
          forgotPasswordFormRef.current?.setErrors({});
          await forgotPasswordSchemaValidation.validate(data, {
            abortEarly: false,
          });
          reduxDispatch(
            authActions.forgotPasswordRequest({
              data: {
                email: data.email,
              },
              functions: {
                error: (err: any) => {
                  helpers.errorHandling(err);
                },
                success: (message: string) => {
                  toast.show({
                    title: message,
                    type: 'success',
                  });
                },
                close: () => {
                  closeModal();
                },
              },
            }),
          );
        } catch (err: any) {
          if (err instanceof Yup.ValidationError) {
            const validationErrors = helpers.getValidationErrors(err);
            forgotPasswordFormRef.current?.setErrors(validationErrors);
          }
        }
      },
      [closeModal, reduxDispatch, toast],
    );

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <ComponentModalBase
      onBackdropClick={closeModal}
      ref={componentModalBaseRef}
    >
      <Content className={hideModal ? 'hideModal' : ''}>
        <Title>Esqueceu sua senha?</Title>

        <Message>
          Preencha o campo abaixo e nós te ajudaremos a recuperar sua senha
        </Message>

        <Forms.Unform
          onSubmit={handleForgotPassword}
          ref={forgotPasswordFormRef}
        >
          <ComponentInputSimple label="E-mail" name="email" />
          <Actions>
            <ComponentButtonBase
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Enviar
            </ComponentButtonBase>
          </Actions>
        </Forms.Unform>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ForgotPassword);
