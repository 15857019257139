import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerData } from '../../index';

export interface IGetCustomerEventVoucherDetailsRequestAction {
  data: {
    voucherId: string;
  };
  functions: {
    error: (err: any) => void;
    goBack: () => void;
  };
}

function getEventVoucherDetailsRequest(
  draft: Draft<ICustomerData>,
  _: PayloadAction<IGetCustomerEventVoucherDetailsRequestAction>,
) {
  draft.getEventVoucherDetails.config.isLoading = true;
  draft.getEventVoucherDetails.details = null;
}

export default getEventVoucherDetailsRequest;
