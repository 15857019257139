import { InputHTMLAttributes, useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Checkbox, Container, Input, Mark } from './styles';

interface ICheckboxInputProps
  extends Pick<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'disabled'> {
  isChecked?: boolean;
  isInstitution?: boolean;
  label: string;
  name: string;
}

const ComponentInputCheckbox: React.FC<ICheckboxInputProps> = ({
  isChecked = false,
  isInstitution = false,
  label,
  name,
  ...rest
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const { defaultValue = isChecked, fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkboxRef.current,
      getValue: (ref: HTMLInputElement) => {
        return ref.checked;
      },
      setValue: (ref: HTMLInputElement, value: boolean) => {
        ref.checked = value;
      },
      clearValue: (ref: HTMLInputElement) => {
        ref.checked = false;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <Checkbox>
        <Input
          className="inputCheckbox"
          defaultChecked={defaultValue}
          isInstitution={isInstitution}
          name={name}
          ref={checkboxRef}
          type="checkbox"
          {...rest}
        />
        <Mark className="checkmark" />
      </Checkbox>
      {label}
    </Container>
  );
};

export default ComponentInputCheckbox;
