import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { EEventPaymentMethodType } from 'models/IEvent';

import { IOrderState } from '../../index';

interface IAddress {
  city: string;
  complement?: string;
  neighborhood: string;
  number: string;
  postalCode: string;
  state: string;
  street: string;
}
export interface ICard {
  brand: string;
  expiration: {
    month: string;
    year: string;
  };
  holder: string;
  number: string;
  securityCode: string;
}

interface IResponsible {
  document: string;
  phone: string;
  token: string;
}

export interface IOrderPurchaseRequestAction {
  data: {
    address: IAddress;
    amount: number;
    card?: Omit<ICard, 'brand'>;
    eventId: number;
    installments: number;
    paymentMethodId: number;
    paymentMethodType: EEventPaymentMethodType;
    responsible: IResponsible;
  };
  functions: {
    error(err: any): void;
    success(): void;
  };
}

function purchaseRequest(
  draft: Draft<IOrderState>,
  _: PayloadAction<IOrderPurchaseRequestAction>,
) {
  draft.config.isLoading = true;
}

export default purchaseRequest;
