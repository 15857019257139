import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostResetPasswordRequest from 'services/httpClient/request/auth/IPostResetPasswordRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { authActions } from '../index';
import { IResetPasswordRequestAction } from '../reducers/resetPassword/request';

function* resetPassword(action: PayloadAction<IResetPasswordRequestAction>) {
  try {
    const body: IPostResetPasswordRequest = {
      newPassword: action.payload.data.newPassword.trim(),
      resetToken: action.payload.data.resetToken,
    };

    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.post,
      'sessions/institution-customers/reset-password',
      body,
    );

    yield put(authActions.resetPasswordSuccess());
    action.payload.functions.success(message);
    action.payload.functions.navigateTo();
  } catch (err: any) {
    yield put(authActions.resetPasswordFailure());
    action.payload.functions.error(err);
  }
}

export default resetPassword;
