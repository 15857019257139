import { Draft } from '@reduxjs/toolkit';

import IGetEventInfoResponse from 'services/httpClient/responses/event/IGetEventInfoResponse';

import { IOrderState } from '../../index';

export type IGetAllEventSuccessWithDataAction = IGetEventInfoResponse;

function purchaseSuccess(draft: Draft<IOrderState>) {
  draft.config.isLoading = false;
}

export default purchaseSuccess;
