import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 5.7rem);
  justify-content: center;
  width: 100%;
`;

export const Warning = styled.h2`
  color: ${colors.gray500};
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 1rem;
`;

export const Message = styled.p`
  color: ${colors.gray200};
  font-size: 1.15rem;
  margin-bottom: 2rem;
  text-align: center;
`;

export const Br = styled.br``;
