import styled, { css } from 'styled-components';

import { shade } from 'polished';

import colors from './colors';

interface IPageTitleContainerProps {
  alignItems?: string;
  displayMobile?: string;
  marginBottom?: string;
}

const PageTitleContainer = styled.div<IPageTitleContainerProps>`
  display: ${props => (props.displayMobile ? props.displayMobile : 'grid')};
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom : '1.5rem'};
  row-gap: 0.5rem;
  @media screen and (min-width: 768px) {
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    display: flex;
    justify-content: space-between;
  }
`;

interface IPageTitleProps {
  color?: string;
  fontSize?: string;
  hasBackButton?: boolean;
}
const PageTitle = styled.h1<IPageTitleProps>`
  align-items: center;
  color: ${props => (props.color ? props.color : colors.darkBlue900)};
  display: grid;
  flex: 1;
  font-size: ${props => (props.fontSize ? props.fontSize : '1.35')}rem;
  font-weight: 600;
  grid-template-columns: 1fr;
  ${props =>
    props.hasBackButton &&
    css`
      grid-template-columns: 2.5rem 1fr;
    `};
`;

interface IPageActionsProps {
  gridTemplateColumns?: string;
}

const PageActions = styled.div<IPageActionsProps>`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: ${props =>
    props.gridTemplateColumns ? props.gridTemplateColumns : '10rem 12rem'};
`;

interface IPageActionProps {
  backgroundColor?: string;
}

const PageAction = styled.button<IPageActionProps>`
  align-items: center;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : colors.orange700};
  border: 0;
  border-radius: 0.375rem;
  color: ${props => (props.color ? props.color : colors.white900)};
  display: flex;
  font-size: 0.93rem;
  font-weight: 600;
  height: 2rem;
  justify-content: center;
  width: 100%;
  & svg {
    color: ${props => (props.color ? props.color : colors.white900)};
    margin-left: 1rem;
  }
  &:hover {
    background-color: ${props =>
      shade(
        0.2,
        props.backgroundColor ? props.backgroundColor : colors.orange700,
      )};
  }
  &:disabled {
    background-color: ${colors.gray200};
    opacity: 1;
  }
`;

export default {
  PageTitleContainer,
  PageTitle,
  PageActions,
  PageAction,
};
