import styled from 'styled-components';

export const Message = styled.p`
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0.5rem 0 1.25rem;
`;

export const Actions = styled.div`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 12rem;
  justify-content: center;
  margin-top: 2rem;
`;
