import { createSlice } from '@reduxjs/toolkit';
import IEvent from 'models/IEvent';

import reducers from './reducers';

export interface IEventData {
  getInfo: {
    config: {
      isLoading: boolean;
    };
    data: IEvent | null;
  };
}

const initialState: IEventData = {
  getInfo: {
    config: {
      isLoading: false,
    },
    data: null,
  },
};

const eventSlice = createSlice({
  name: '@event',
  initialState,
  reducers,
});

export const eventActions = eventSlice.actions;
export const eventReducers = eventSlice.reducer;
