import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerData } from '../../index';

export interface IGetAllCustomerVouchersRequestAction {
  data: {
    eventId: string;
  };
  functions: {
    error: (err: any) => void;
  };
}

function getAllEventVoucherRequest(
  draft: Draft<ICustomerData>,
  _: PayloadAction<IGetAllCustomerVouchersRequestAction>,
) {
  draft.getAllEventVoucher.config.errorMessage = '';
  draft.getAllEventVoucher.config.isLoading = true;
  draft.getAllEventVoucher.list = [];
}

export default getAllEventVoucherRequest;
