import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

export interface IFirstAccessRequestAction {
  data: {
    email: string;
    password: string;
  };
  functions: {
    error(err: any): void;
    navigateTo(): void;
  };
}

function firstAccessRequest(
  draft: Draft<IAuthData>,
  _: PayloadAction<IFirstAccessRequestAction>,
) {
  draft.firstAccess.config.isLoading = true;
}

export default firstAccessRequest;
