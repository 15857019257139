import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { addHours, parseISO } from 'date-fns';
import formatters from 'formatters';

import IGetEventInfoResponse from 'services/httpClient/responses/event/IGetEventInfoResponse';

import { IEventData } from '../../index';

export type IGetAllEventSuccessWithDataAction = IGetEventInfoResponse;

function getInfoSuccess(
  draft: Draft<IEventData>,
  action: PayloadAction<IGetAllEventSuccessWithDataAction>,
) {
  draft.getInfo.config.isLoading = false;
  draft.getInfo.data = {
    installmentsDateLimit: action.payload.installmentsDateLimit,
    id: action.payload.id,
    institution: {
      name: action.payload.institution.name,
      email: action.payload.institution.email,
      phone: formatters.phone(action.payload.institution.phone),
    },
    age: {
      children: action.payload.age.children,
      max: action.payload.age.max,
      min: action.payload.age.min,
    },
    date: addHours(parseISO(action.payload.date), 3),
    location: {
      city: action.payload.location.city,
      name: action.payload.location.name,
      state: action.payload.location.state,
    },
    name: action.payload.name,
    paymentMethods: action.payload.paymentMethods,
    picture: action.payload.picture,
    quantityPurchase: action.payload.quantityPurchase,
    restriction: {
      purchase: action.payload.restriction.purchase,
    },
    terms: action.payload.terms,
    allowInstallmentsAfter: action.payload.allowInstallmentsAfter,
    participant: {
      age: action.payload.participant.age,
      gender: action.payload.participant.gender.toLowerCase(),
      name: action.payload.participant.name,
      responsible: {
        document: formatters.cpf(
          action.payload.participant.responsible.document,
        ),
        phone: formatters.phone(action.payload.participant.responsible.phone),
        email: action.payload.participant.responsible.email,
        name: action.payload.participant.responsible.name,
      },
    },
  };
}

export default getInfoSuccess;
