import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import IGetCustomerEventVoucherDetailsResponse from 'services/httpClient/responses/customer/IGetCustomerEventVoucherDetailsResponse';

import { ICustomerData } from '../../index';

interface IGetCustomerAllVoucherSuccessWithDataAction {
  data: IGetCustomerEventVoucherDetailsResponse;
}

function getEventVoucherDetailsSuccess(
  draft: Draft<ICustomerData>,
  action: PayloadAction<IGetCustomerAllVoucherSuccessWithDataAction>,
) {
  draft.getEventVoucherDetails.config.isLoading = false;
  draft.getEventVoucherDetails.details = {
    event: {
      name: action.payload.data.event.name,
    },
    id: action.payload.data.id,
    identifier: action.payload.data.identifier,
    order: {
      amount: formatters.money(action.payload.data.order.amount),
      id: action.payload.data.order.id,
      installments: action.payload.data.order.installments,
      paymentMethod: action.payload.data.order.paymentMethod,
      payments: action.payload.data.order.payments.map(payment => ({
        ...payment,
        amount: formatters.money(payment.amount),
        paidDate: payment.paidDate
          ? formatters.date(new Date(payment.paidDate), 'dd/MM/yyyy')
          : '-',
        dueDate: formatters.date(new Date(payment.dueDate), 'dd/MM/yyyy'),
        status: payment.status,
      })),
      status: action.payload.data.order.status,
    },
    participant: {
      age: action.payload.data.participant.age,
      gender: action.payload.data.participant.gender.toLowerCase(),
      name: action.payload.data.participant.name,
    },
    responsible: {
      document: formatters.cpf(action.payload.data.responsible.document),
      email: action.payload.data.responsible.email,
      name: action.payload.data.responsible.name,
      phone: formatters.phone(action.payload.data.responsible.phone),
    },
  };
}

export default getEventVoucherDetailsSuccess;
