import getAllEventEmptyMessage from './getAllEventEmptyMessage';
import getAllEventErrorMessage from './getAllEventErrorMessage';
import getAllEventIsLoading from './getAllEventIsLoading';
import getAllEventList from './getAllEventList';
import getAllEventVoucherErrorMessage from './getAllEventVoucherErrorMessage';
import getAllEventVoucherIsLoading from './getAllEventVoucherIsLoading';
import getAllEventVoucherList from './getAllEventVoucherList';
import getEventVoucherDetails from './getEventVoucherDetails';
import getEventVoucherDetailsIsLoading from './getEventVoucherDetailsIsLoading';

const customerSelectors = {
  getAllEventList,
  getAllEventIsLoading,
  getAllEventEmptyMessage,
  getAllEventErrorMessage,
  getAllEventVoucherList,
  getAllEventVoucherIsLoading,
  getAllEventVoucherErrorMessage,
  getEventVoucherDetailsIsLoading,
  getEventVoucherDetails,
};

export default customerSelectors;
