import styled from 'styled-components';

import { shade } from 'polished';

import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column-end: 6;
  grid-column-start: 1;
  justify-content: center;
  padding: 3rem;
`;

export const ErrorImage = styled.img`
  margin-bottom: 1.5rem;
  max-width: 6.5rem;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
`;

export const TryAgain = styled.button`
  background-color: ${colors.darkBlue900};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.white900};
  font-size: 0.875rem;
  font-weight: 500;
  height: 2rem;
  margin: 1rem 0 0;
  padding: 0 1.5rem;

  @media (min-width: 850px) {
    &:hover {
      background-color: ${shade(0.1, colors.darkBlue900)};
    }
  }
`;
