import styled from 'styled-components';

import colors from 'styles/colors';

export const Content = styled.div`
  align-items: center;
  display: grid;
  margin: 8rem auto 0;
  max-width: 25rem;
`;

export const Title = styled.h2`
  color: ${colors.darkBlue900};
  font-weight: 700;
  margin-bottom: 0.25rem;
`;

export const Subtitle = styled.p`
  color: ${colors.gray500};
  font-size: 0.875rem;
  margin-bottom: 2rem;
`;

export const Actions = styled.div`
  display: grid;
  margin: 2.5rem auto 0;
  max-width: 18rem;
`;

export const Message = styled.p`
  font-size: 0.875rem;
  margin: 2rem auto 0;
  text-align: center;
`;

export const GoAuth = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
  margin: 0.375rem auto 0;
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
`;
