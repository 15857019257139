function getDayWeek(date: Date): string {
  const position = date.getDay();
  switch (position) {
    case 0:
      return 'DOM';
    case 1:
      return 'SEG';
    case 2:
      return 'TER';
    case 3:
      return 'QUA';
    case 4:
      return 'QUI';
    case 5:
      return 'SEX';
    default:
      return 'SAB';
  }
}

export default getDayWeek;
