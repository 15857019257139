import getAllEventFailure from './getAllEvent/failure';
import getAllEventRequest from './getAllEvent/request';
import getAllEventSuccessWithData from './getAllEvent/successWithData';
import getAllEventSuccessWithoutData from './getAllEvent/successWithoutData';
import getAllEventVoucherFailure from './getAllEventVoucher/failure';
import getAllEventVoucherRequest from './getAllEventVoucher/request';
import getAllEventVoucherSuccess from './getAllEventVoucher/success';
import getEventVoucherDetailsFailure from './getEventVoucherDetails/failure';
import getEventVoucherDetailsRequest from './getEventVoucherDetails/request';
import getEventVoucherDetailsSuccess from './getEventVoucherDetails/success';

const reducers = {
  getAllEventFailure,
  getAllEventRequest,
  getAllEventSuccessWithData,
  getAllEventSuccessWithoutData,
  getAllEventVoucherFailure,
  getAllEventVoucherRequest,
  getAllEventVoucherSuccess,
  getEventVoucherDetailsFailure,
  getEventVoucherDetailsRequest,
  getEventVoucherDetailsSuccess,
};

export default reducers;
