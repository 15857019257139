import { Draft } from '@reduxjs/toolkit';

import { ICustomerData } from '../../index';

function getAllEventVoucherFailure(draft: Draft<ICustomerData>) {
  draft.getAllEventVoucher.config.errorMessage =
    'Falha ao carregar os vouchers deste evento';
  draft.getAllEventVoucher.config.isLoading = false;
}

export default getAllEventVoucherFailure;
