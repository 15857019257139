import styled, { css } from 'styled-components';

import { Form as Unform } from '@unform/web';
import { shade } from 'polished';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Main = styled.div`
  display: grid;
`;

export const Container = styled(Unform)`
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    'event '
    'details'
    'participants'
    'terms'
    'payment';
  min-height: 100%;
  @media screen and (min-width: 768px) {
    grid-gap: 0 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'event details'
      'event participants'
      'terms payment';
  }
`;

interface IContentProps {
  gridArea: string;
  overflow?: string;
}

export const Content = styled.div<IContentProps>`
  display: grid;
  grid-area: ${props => (props.gridArea ? props.gridArea : '')};
  height: fit-content;
  overflow: ${props => (props.overflow ? props.overflow : 'hidden')};
  width: 100%;
  & + div {
    margin-bottom: 1rem;
  }
`;

export const Subtitle = styled.h2`
  color: ${colors.orange700};
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  @media screen and (min-width: 1024px) {
    margin-bottom: 1rem;
  }
`;

interface IThumbnailProps {
  isLoading?: boolean;
}

export const Thumbnail = styled.div<IThumbnailProps>`
  border-radius: 0.375rem;
  height: 13rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  position: relative;
  @media screen and (min-width: 1024px) {
    height: 15rem;
  }
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 12rem;
      width: 100%;
      @media screen and (min-width: 1024px) {
        height: 14rem;
      }
    `}
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const Info = styled.div`
  align-items: center;
  background-color: ${colors.white900};
  border: 1px solid ${colors.white100};
  border-radius: 0.375rem;
  box-shadow: 0 0 1px 0 ${colors.boxShadow};
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 3rem 1fr;
  max-height: 5rem;
  padding: 1rem;
`;

interface IDayProps {
  isLoading?: boolean;
}

export const Day = styled.p<IDayProps>`
  align-items: center;
  color: ${colors.orange900};
  display: grid;
  font-size: 1.115rem;
  font-weight: 600;
  justify-content: center;

  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 0.875rem;
      width: 100%;
    `}
`;

interface ISmallProps {
  isLoading?: boolean;
  smallPrice?: boolean;
}

export const Small = styled.small<ISmallProps>`
  color: ${colors.gray500};
  display: grid;
  font-size: 0.875rem;
  font-weight: 400;

  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 0.875rem;
      width: 100%;
    `}

  ${props =>
    props.smallPrice &&
    css`
      font-size: 0.75rem;
      margin-top: 0.25rem;
    `};
`;

export const Details = styled.div`
  display: grid;
  row-gap: 0.25rem;
  width: 100%;
`;

interface INameProps {
  isLoading?: boolean;
}

export const Name = styled.h4<INameProps>`
  color: ${colors.darkBlue900};
  font-size: 1rem;
  font-weight: 600;
  text-align: left;

  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 0.875rem;
      width: 100%;
    `}
`;

interface ILocationProps {
  isLoading?: boolean;
}

export const Location = styled.p<ILocationProps>`
  font-size: 0.75rem;
  text-align: left;

  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 0.875rem;
      width: 100%;
    `}
`;

interface ICardProps {
  padding?: string;
}

export const Card = styled.div<ICardProps>`
  background-color: ${colors.white900};
  border: 1px solid ${colors.white100};
  border-radius: 0.375rem;
  box-shadow: 0 0 1px 0 ${colors.boxShadow};
  padding: ${props => (props.padding ? props.padding : '1.5')}rem;
`;

export const TermsContent = styled.div`
  height: 20rem;
  overflow-y: auto;
  padding-right: 1rem;
  position: relative;
  &::-webkit-scrollbar {
    background: ${colors.transparent};
    height: 0.5rem;
    width: 0.5rem;
  }
  &::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.white100};
    border-radius: 0.25rem;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.gray100};
    border-radius: 0.25rem;
    height: 0.5rem;
    width: 0.5rem;
  }
`;

export const Terms = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: justify;
`;

export const Line = styled.hr`
  background-color: ${colors.gray100};
  border: 0;
  height: 1px;
  margin: 1.5rem 0;
`;

interface IGridProps {
  informative?: boolean;
}

export const Grid = styled.div<IGridProps>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  ${props =>
    props.informative &&
    css`
      grid-gap: 0.5rem;
      margin-bottom: 0;
      margin-top: 1rem;
    `};
`;

export const Item = styled.div`
  display: grid;
  row-gap: 0.25rem;
`;

interface ILabelProps {
  marginBottom?: number;
  marginTop?: number;
}

export const Label = styled.label<ILabelProps>`
  color: ${colors.darkBlue900};
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}rem;
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}rem;
`;

export const Available = styled.span`
  display: flex;
  margin-left: auto;
`;

interface IValueProps {
  isLoading?: boolean;
  isParticipant?: boolean;
}

export const Value = styled.p<IValueProps>`
  color: ${colors.gray500};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: ${props => (props.isParticipant ? 'capitalize' : 'initial')};
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 0.875rem;
      width: 100%;
    `}
`;

interface IRowProps {
  marginTop?: number;
}

export const Row = styled.div<IRowProps>`
  align-items: center;
  column-gap: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${props => (props.marginTop ? props.marginTop : 0.25)}rem;
`;

interface ITicketActionsProps {
  showAdultPrice?: boolean;
  showChildPrice?: boolean;
}

export const TicketActions = styled.div<ITicketActionsProps>`
  align-items: center;
  column-gap: 0.25rem;
  display: grid;
  grid-template-columns: repeat(3, 1.5rem);
  ${props =>
    props.showAdultPrice &&
    css`
      grid-template-columns: 8rem repeat(3, 1.5rem);
    `};
  ${props =>
    props.showChildPrice &&
    css`
      grid-template-columns: 8rem repeat(3, 1.5rem);
    `};
`;

interface IPriceProps {
  informative?: boolean;
  total?: boolean;
}

export const Price = styled.p<IPriceProps>`
  font-size: 0.975rem;
  margin-right: 1rem;
  text-align: right;

  ${props =>
    props.total &&
    css`
      color: ${colors.darkBlue900};
      font-weight: 600;
      margin-right: 0;
      margin-top: 0.25rem;
    `};

  ${props =>
    props.informative &&
    css`
      color: ${colors.gray500};
      font-size: 0.75rem;
      margin-right: 0;
    `};
`;

export const AlterQuantityButton = styled.button`
  background-color: ${colors.transparent};
  border: 0;
  font-size: 0;

  &:hover {
    opacity: 0.75;
  }
`;

export const Quantity = styled.p`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
`;

interface ITicketProps {
  total?: boolean;
}

export const Ticket = styled.p<ITicketProps>`
  color: ${colors.gray500};
  font-size: 0.75rem;

  ${props =>
    props.total &&
    css`
      color: ${colors.darkBlue900};
      font-size: 0.875rem;
      font-weight: 500;
      margin-top: 0.25rem;
    `};
`;

export const TicketType = styled.p`
  color: ${colors.orange700};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: right;
`;

export const AddParticipantButton = styled.button`
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.gray500};
  display: flex;
  font-size: 0.875rem;
  margin: 1.5rem auto 0;
  text-decoration: underline;
`;

export const TermsMessage = styled.div`
  column-gap: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.115rem;
  margin: 1rem auto 0;
  max-width: 75%;
  text-align: center;
  width: fit-content;
  & a {
    font-weight: 600;
    text-decoration: underline;
    &:hover {
      opacity: 0.75;
    }
  }
`;

export const QrCodeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0;
  width: 100%;
`;

export const QrCodeImage = styled.img`
  height: 20rem;
  width: 20rem;
`;

export const PaymentQrCodeButton = styled.button`
  background-color: ${colors.darkBlue900};
  border: 0;
  border-radius: 4px;
  color: ${colors.white900};
  padding: 0.6rem;

  :hover {
    background-color: ${shade(0.15, colors.darkBlue900)};
  }
`;

export const PaymentText = styled.p`
  color: ${colors.gray500};
  margin-bottom: 1rem;
`;
