import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IGetCustomerEventVoucherListResponse } from 'services/httpClient/responses/customer/IGetAllCustomerEventVoucherResponse';

import { ICustomerData } from '../../index';

interface IGetCustomerAllVoucherSuccessWithDataAction {
  list: IGetCustomerEventVoucherListResponse[];
}

function getAllEventVoucherSuccess(
  draft: Draft<ICustomerData>,
  action: PayloadAction<IGetCustomerAllVoucherSuccessWithDataAction>,
) {
  draft.getAllEventVoucher.config.isLoading = false;
  draft.getAllEventVoucher.list = action.payload.list.map(item => ({
    id: item.id,
    identifier: item.identifier,
    participant: {
      name: item.participant.name,
      age: item.participant.age,
      gender: item.participant.gender.toLowerCase(),
    },
    order: {
      status: item.order.status,
      amount: formatters.money(item.order.amount),
      installments: item.order.installments,
      paymentMethod: item.order.paymentMethod,
    },
    event: {
      name: item.event.name,
      date: formatters.date(item.event.date),
    },
  }));
}

export default getAllEventVoucherSuccess;
