import styled from 'styled-components';

import colors from 'styles/colors';

export const HeaderBackground = styled.div`
  background-color: ${colors.darkBlue900};
  display: flex;
  height: 4rem;
  padding: 0 10rem;
  position: relative;
  width: 100%;
`;

export const Header = styled.header`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 10rem);
  justify-content: flex-start;
  width: 100%;
  @media screen and (min-width: 1440px) {
    max-width: 75rem;
  }
  @media screen and (min-width: 1600px) {
    max-width: 90rem;
  }
  @media screen and (min-width: 1920px) {
    max-width: 100rem;
  }
`;

export const Details = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Label = styled.p`
  color: ${colors.white900};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
`;
