export default {
  auth: '/',
  resetPassword: '/nova-senha',
  event: (slug: string) => `/evento/${slug}`,
  reservationAlreadyMade: '/reserva-efetuada',
  eventNotFound: '/evento-nao-encontrado',
  terms: '/termos-de-uso',
  lgpdPolitic: '/politica-de-protecao-de-dados',
  success: '/evento/sucesso',
  myAccount: '/minha-conta',
  changePassword: '/minha-conta/alterar-senha',
  eventVouchers: (id: number) => `/minha-conta/${id}/vouchers`,
  eventVoucherDetails: (id: number) => `/minha-conta/vouchers/${id}/detalhes`,
  myEvent: {
    list: '/meus-eventos',
  },
};
