import { ButtonHTMLAttributes } from 'react';

import { PuffLoader } from 'react-spinners';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Container, IContainerProps } from './styles';

interface IComponentButtonBaseProps
  extends IContainerProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

const ComponentButtonBase: React.FC<IComponentButtonBaseProps> = ({
  backgroundColor,
  borderColor,
  borderRadius,
  children,
  color,
  disabled,
  height,
  isLoading = false,
  type = 'button',
  width,
  ...props
}) => {
  return (
    <Container
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      color={color}
      disabled={isLoading || disabled}
      height={height}
      type={type}
      width={width}
      {...props}
    >
      <ComponentIsVisible when={!isLoading}>{children}</ComponentIsVisible>
      <ComponentIsVisible when={isLoading}>
        <PuffLoader
          color={color || colors.white900}
          size={24}
          speedMultiplier={2}
        />
      </ComponentIsVisible>
    </Container>
  );
};

export default ComponentButtonBase;
