import styled, { css } from 'styled-components';

import Tooltip from 'components/utils/Tooltip';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Label = styled.label`
  color: ${colors.gray200};
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`;

export interface IFieldContainerProps {
  backgroundColor?: string;
  height?: string;
  isCard?: boolean;
  isErrored?: boolean;
  isFilled: boolean;
  isFocused: boolean;
  isReadOnly?: boolean;
  isSecure?: boolean;
}

export const FieldContainer = styled.div<IFieldContainerProps>`
  align-items: center;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : colors.white100};
  border: 1px solid ${colors.gray100};
  border-radius: 0.375rem;
  color: ${colors.gray500};
  display: grid;
  grid-template-columns: ${props => (props.isSecure ? '1fr 2rem' : '1fr')};
  height: ${props => (props.height ? props.height : '2rem')};
  min-width: 0;
  padding: 0 0.25rem 0 0.5rem;
  position: relative;
  width: 100%;
  ${props =>
    props.isErrored &&
    css`
      border-color: ${colors.red700};
      grid-template-columns: ${props.isSecure
        ? '1fr repeat(2, 2rem)'
        : ' 1fr 2rem'};
    `};

  ${props =>
    props.isFocused &&
    css`
      border-color: ${colors.black900};
      color: ${colors.black900};
    `};

  ${props =>
    props.isFilled &&
    css`
      color: ${colors.black900};
    `};

  ${props =>
    props.isReadOnly &&
    css`
      background-color: ${colors.background};
      border-color: ${colors.background};
    `};

  ${props =>
    props.isCard &&
    css`
      border-color: ${props.isErrored ? colors.red700 : colors.gray100};
      grid-template-columns: ${props.isErrored
        ? '1fr repeat(2, 2.5rem)'
        : ' 1fr 2.5rem'};
      justify-content: center;
    `};
`;

export interface IInputProps {
  isLoading?: boolean;
  textAlign?: string;
  uppercase?: boolean;
}

export const Input = styled.input<IInputProps>`
  background: ${colors.transparent};
  border: 0;
  border-radius: 0.375rem;
  color: ${colors.black900};
  flex: 1;
  font-size: 0.85rem;
  height: 100%;
  max-width: 100%;
  min-width: 0px;
  padding-right: ${props => (props.textAlign === 'right' ? '1rem' : 0)};
  text-align: ${props => (props.textAlign ? props.textAlign : 'initial')};
  &:read-only {
    cursor: not-allowed;
  }
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      cursor: not-allowed;
    `}
  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const ToggleSecure = styled.button`
  align-items: center;
  background: ${colors.transparent};
  border: 0;
  color: ${colors.gray500};
  display: flex;
  font-size: 0;
  justify-content: center;
  outline: none;
`;

export const CardFlag = styled.div`
  align-items: center;
  display: flex;
  height: 85%;
  justify-content: center;
  margin: 0 auto;
  padding: 0.25rem;
  position: relative;
  width: 85%;
`;

export const Brand = styled.img`
  height: 100%;
  width: 100%;
`;

export const Error = styled(Tooltip)``;
