import ICustomerEventVoucherDetails from 'models/ICustomerEventVoucherDetails';

import { ReduxStore } from 'store/types';

function getEventVoucherDetails(
  state: ReduxStore,
): ICustomerEventVoucherDetails | null {
  return state.customer.getEventVoucherDetails.details;
}

export default getEventVoucherDetails;
