import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { FormHandles, SubmitHandler } from '@unform/core';
import helpers from 'helpers';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import Containers from 'styles/containers';
import Forms from 'styles/forms';

import { Actions, Content, GoAuth, Message, Subtitle, Title } from './styles';

interface IResetPasswordFormData {
  confirmNewPassword: string;
  newPassword: string;
}

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().trim().required('Informe a nova senha'),
  confirmNewPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], 'Senhas são diferentes')
    .required('Informe a confirmação da nova senha'),
});

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = (): JSX.Element => {
  const toast = useToast();
  const navigate = useNavigate();
  const query = useQuery();
  const reduxDispatch = useReduxDispatch();
  const isLoading = useReduxSelector(authSelectors.resetPasswordIsLoading);

  const resetPasswordFormRef = useRef<FormHandles>(null);

  const token = query.get('reset-token') as string | undefined;

  const handleResetPassword: SubmitHandler<IResetPasswordFormData> =
    useCallback(
      async data => {
        try {
          if (!token) {
            toast.show({
              title: 'Falha ao redefinir senha',
              description:
                'Token inválido, solicite uma nova recuperação de senha',
              type: 'error',
            });
            return;
          }
          resetPasswordFormRef.current?.setErrors({});
          await resetPasswordSchema.validate(data, {
            abortEarly: false,
          });
          reduxDispatch(
            authActions.resetPasswordRequest({
              data: {
                newPassword: data.newPassword,
                resetToken: token,
              },
              functions: {
                error: (err: any) => {
                  helpers.errorHandling(err);
                },
                success: (message: string) => {
                  toast.show({
                    title: message,
                    type: 'success',
                  });
                },
                navigateTo() {
                  navigate(pages.auth, { replace: true });
                },
              },
            }),
          );
        } catch (err: any) {
          if (err instanceof Yup.ValidationError) {
            const validationErrors = helpers.getValidationErrors(err);
            resetPasswordFormRef.current?.setErrors(validationErrors);
          }
        }
      },
      [navigate, reduxDispatch, toast, token],
    );

  const goBack = useCallback(() => {
    navigate(pages.auth);
  }, [navigate]);

  useEffect(() => {
    if (!token) {
      goBack();
    }
  }, [goBack, token]);

  return (
    <Containers.Main>
      <Containers.Global>
        <Content>
          <Title>Recuperar sua senha</Title>
          <Subtitle>
            Preencha os campos abaixo para gerar uma nova senha
          </Subtitle>

          <Forms.Unform
            onSubmit={handleResetPassword}
            ref={resetPasswordFormRef}
          >
            <Forms.Grid>
              <ComponentInputPassword label="Nova senha" name="newPassword" />
            </Forms.Grid>
            <Forms.Grid>
              <ComponentInputPassword
                label="Confirmar nova senha"
                name="confirmNewPassword"
              />
            </Forms.Grid>

            <Actions>
              <ComponentButtonBase
                disabled={isLoading}
                height="2.5rem"
                isLoading={isLoading}
                type="submit"
              >
                Confirmar
              </ComponentButtonBase>
              <Message>
                Deseja cancelar esta ação?
                <GoAuth onClick={goBack} type="button">
                  Voltar ao início
                </GoAuth>
              </Message>
            </Actions>
          </Forms.Unform>
        </Content>
      </Containers.Global>
    </Containers.Main>
  );
};

export default ResetPassword;
