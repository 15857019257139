import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, delay, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetPixResponse from 'services/httpClient/responses/pix/IGetPixResponse';

import { pixActions } from '../index';
import { ICheckIsPaidRequestAction } from '../reducers/checkIsPaid/request';

function* checkIsPaid(action: PayloadAction<ICheckIsPaidRequestAction>) {
  try {
    const params = {
      eventId: action.payload.data.eventId,
      orderId: action.payload.data.orderId,
      paymentId: action.payload.data.paymentId,
      responsibleToken: action.payload.data.responsibleToken,
    };
    const response: AxiosResponse<IGetPixResponse> = yield call(
      httpClient.get,
      `orders/privates`,
      { params },
    );
    if (response.data.isPaid) {
      yield put(pixActions.checkIsPaidSuccess());
      action.payload.functions.success();
      return;
    }
    yield delay(3000);
    yield put(
      pixActions.checkIsPaidRequest({
        data: {
          eventId: action.payload.data.eventId,
          orderId: action.payload.data.orderId,
          paymentId: action.payload.data.paymentId,
          responsibleToken: action.payload.data.responsibleToken,
        },
        functions: {
          success() {
            action.payload.functions.success();
          },
        },
      }),
    );
  } catch (err) {
    yield put(pixActions.checkIsPaidFailure());
  }
}

export default checkIsPaid;
