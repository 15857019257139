import { Draft } from '@reduxjs/toolkit';

import { ICustomerData } from '../../index';

function getAllEventFailure(draft: Draft<ICustomerData>) {
  draft.getAllEvent.config.isLoading = false;
  draft.getAllEvent.config.errorMessage = 'Falha ao carregar os eventos';
}

export default getAllEventFailure;
