import styled, { css } from 'styled-components';

import Tooltip from 'components/utils/Tooltip';

import colors from 'styles/colors';

interface IContainerProps {
  gridColumn?: string;
}

export const Container = styled.div<IContainerProps>`
  grid-column: ${props => (props.gridColumn ? props.gridColumn : 'initial')};
  margin-top: 0.5rem;
  width: 100%;

  @media screen and (min-width: 1024px) {
    grid-column: initial;
  }
`;

export const Label = styled.label`
  color: ${colors.gray200};
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`;

interface ISelectContainerProps {
  isErrored?: boolean;
  isFilled: boolean;
  isFocused: boolean;
}

export const SelectContainer = styled.div<ISelectContainerProps>`
  align-items: center;
  background: ${colors.white100};
  border: 1px solid ${colors.gray100};
  border-radius: 0.375rem;
  display: grid;
  height: 2rem;
  position: relative;
  width: 100%;
  ${props =>
    props.isErrored &&
    css`
      border-color: ${colors.red700};
      grid-template-columns: 1fr 2rem;
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: ${colors.gray100};
    `}
`;

export const Error = styled(Tooltip)``;
