import changePasswordFailure from './changePassword/failure';
import changePasswordRequest from './changePassword/request';
import changePasswordSuccess from './changePassword/success';
import firstAccessFailure from './firstAccess/failure';
import firstAccessRequest from './firstAccess/request';
import firstAccessSuccess from './firstAccess/success';
import forgotPasswordFailure from './forgotPassword/failure';
import forgotPasswordRequest from './forgotPassword/request';
import forgotPasswordSuccess from './forgotPassword/success';
import logoutRequest from './logout/request';
import resetPasswordFailure from './resetPassword/failure';
import resetPasswordRequest from './resetPassword/request';
import resetPasswordSuccess from './resetPassword/success';
import setProfile from './set/profile';
import signInFailure from './signIn/failure';
import signInRequest from './signIn/request';
import signInSuccess from './signIn/success';

const reducers = {
  changePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  firstAccessRequest,
  firstAccessSuccess,
  firstAccessFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  logoutRequest,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  setProfile,
  signInRequest,
  signInSuccess,
  signInFailure,
};

export default reducers;
