import ComponentIsVisible from 'components/utils/IsVisible';

import { Container } from './styles';

interface IVoucherItemLoadingProps {
  rows?: number;
  show: boolean;
}

const VoucherItemLoading = ({
  rows = 6,
  show,
}: IVoucherItemLoadingProps): JSX.Element => {
  const loadingItems = new Array(rows);

  for (let i = 0; i < loadingItems.length; i += 1) {
    loadingItems[i] = i;
  }

  return (
    <ComponentIsVisible when={show}>
      {loadingItems.map(position => (
        <Container key={position} />
      ))}
    </ComponentIsVisible>
  );
};

export default VoucherItemLoading;
