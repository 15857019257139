import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerData } from '../../index';

export interface IGetAllCustomerEventSuccessWithoutDataAction {
  message: string;
}
function getAllEventSuccessWithoutData(
  draft: Draft<ICustomerData>,
  action: PayloadAction<IGetAllCustomerEventSuccessWithoutDataAction>,
) {
  draft.getAllEvent.config.emptyMessage = action.payload.message;
  draft.getAllEvent.config.isLoading = false;
}

export default getAllEventSuccessWithoutData;
