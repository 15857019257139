import { PayloadAction } from '@reduxjs/toolkit';
import cardValidator from 'card-validator';
import formatters from 'formatters';
import { call, put } from 'redux-saga/effects';

import { cardBrandsNames } from 'constants/cardBrands';

import httpClient from 'services/httpClient';
import IPostOrderRequest from 'services/httpClient/request/order/IPostOrderRequest';

import { orderActions } from '../index';
import {
  ICard,
  IOrderPurchaseRequestAction,
} from '../reducers/purchase/request';

function* purchase(action: PayloadAction<IOrderPurchaseRequestAction>) {
  try {
    let cardSerialized: ICard | undefined;
    if (action.payload.data.card) {
      const cardValidation = cardValidator.number(
        formatters.removeMask(action.payload.data.card.number).trim(),
      );
      const cardType = cardValidation.card?.type || '';
      const cardBrandSelected = cardBrandsNames[cardType] || '';
      cardSerialized = {
        brand: cardBrandSelected,
        expiration: {
          month: action.payload.data.card.expiration.month,
          year: action.payload.data.card.expiration.year,
        },
        holder: action.payload.data.card.holder.toUpperCase().trim(),
        number: formatters.removeMask(action.payload.data.card.number).trim(),
        securityCode: action.payload.data.card.securityCode,
      };
    }
    const body: IPostOrderRequest = {
      address: {
        city: action.payload.data.address.city,
        neighborhood: action.payload.data.address.neighborhood,
        number: action.payload.data.address.number,
        postalCode: formatters.removeMask(
          action.payload.data.address.postalCode,
        ),
        state: action.payload.data.address.state,
        street: action.payload.data.address.street,
        complement: action.payload.data.address.complement || undefined,
      },
      responsible: {
        document: formatters.removeMask(
          action.payload.data.responsible.document,
        ),
        phone: formatters.removeMask(action.payload.data.responsible.phone),
        token: action.payload.data.responsible.token,
      },
      amount: action.payload.data.amount,
      card: cardSerialized,
      eventId: action.payload.data.eventId,
      eventPaymentMethodId: action.payload.data.paymentMethodId,
      paymentMethodType: action.payload.data.paymentMethodType,
      installments: action.payload.data.installments,
    };
    yield call(httpClient.post, 'orders/privates', body);
    yield put(orderActions.purchaseSuccess());
    action.payload.functions.success();
  } catch (err) {
    yield put(orderActions.purchaseFailure());
    action.payload.functions.error(err);
  }
}

export default purchase;
