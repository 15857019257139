import { all, takeLatest } from 'redux-saga/effects';

import { customerActions } from '../index';
import getAllEvent from './getAllEvent';
import getAllEventVoucher from './getAllEventVoucher';
import getEventVoucherDetails from './getEventVoucherDetails';

const customerSagas = all([
  takeLatest(customerActions.getAllEventRequest, getAllEvent),
  takeLatest(customerActions.getAllEventVoucherRequest, getAllEventVoucher),
  takeLatest(
    customerActions.getEventVoucherDetailsRequest,
    getEventVoucherDetails,
  ),
]);

export default customerSagas;
