import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  margin: 4rem auto;
  max-width: 70rem;
  @media screen and (min-width: 1440px) {
    max-width: 75rem;
  }
  @media screen and (min-width: 1600px) {
    max-width: 90rem;
  }
  @media screen and (min-width: 1920px) {
    max-width: 100rem;
  }
`;

export const Title = styled.h1`
  align-items: center;
  color: ${colors.darkBlue900};
  display: grid;
  flex: 1;
  font-size: 1.875rem;
  font-weight: 800;
`;

export const Text = styled.p`
  line-height: 1.25rem;
  margin: 1rem 0;
`;

export const Bold = styled.b``;

interface IListTitleProps {
  marginTop?: boolean;
}
export const ListTitle = styled.p<IListTitleProps>`
  font-size: 1.115rem;
  line-height: 1.375rem;
  margin-bottom: 0.5rem;
  ${props =>
    props.marginTop &&
    css`
      margin-top: 2rem;
    `}
`;

interface IListTextProps {
  marginTop?: boolean;
}
export const ListText = styled.p<IListTextProps>`
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
  padding: 0 2.5rem;
  ${props =>
    props.marginTop &&
    css`
      margin-top: 1rem;
    `}
`;
