import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

interface IParticipant {
  age: string;
  document: string;
  gender: string;
  name: string;
  phone: string;
}

export interface ITickets {
  participant: IParticipant;
  type: string;
}

export interface IOrderState {
  config: {
    isLoading: boolean;
  };
}

const initialState: IOrderState = {
  config: {
    isLoading: false,
  },
};

const orderSlice = createSlice({
  name: '@order',
  initialState,
  reducers,
});

export const orderActions = orderSlice.actions;
export const orderReducers = orderSlice.reducer;
