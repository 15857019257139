import { useCallback, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { FormHandles, SubmitHandler } from '@unform/core';
import helpers from 'helpers';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';
import ComponentInputSimple from 'components/input/Simple';
import ComponentMenu from 'components/Menu';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import Forms from 'styles/forms';

import {
  Actions,
  Container,
  Content,
  FormContent,
  FormSubtitle,
  FormTitle,
  Observation,
  Text,
  Title,
} from './styles';

interface IAuthFormData {
  email: string;
  password: string;
}

const authSchemaValidation = Yup.object().shape({
  email: Yup.string()
    .email('Informe um e-mail válido (ex: email@email.com)')
    .required('Informe o e-mail'),
  password: Yup.string().required('Informe a senha'),
});

const AlreadyBought = (): JSX.Element => {
  const navigate = useNavigate();
  const authFormRef = useRef<FormHandles>(null);
  const reduxDispatch = useReduxDispatch();

  const isLoadingSignIn = useReduxSelector(authSelectors.signInIsLoading);

  const handleAuthenticate: SubmitHandler<IAuthFormData> = useCallback(
    async data => {
      try {
        authFormRef.current?.setErrors({});
        await authSchemaValidation.validate(data, {
          abortEarly: false,
        });
        reduxDispatch(
          authActions.signInRequest({
            data: {
              email: data.email,
              password: data.password,
            },
            functions: {
              error: (err: any) => {
                helpers.errorHandling(err);
              },
              navigateTo: () => {
                navigate(pages.myAccount, { replace: true });
              },
            },
          }),
        );
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = helpers.getValidationErrors(err);
          authFormRef.current?.setErrors(validationErrors);
        }
      }
    },
    [navigate, reduxDispatch],
  );

  return (
    <Container>
      <ComponentMenu />
      <Content>
        <Title>Ops... parece que a sua reserva já foi efetuada!</Title>
        <Text>
          Agora você pode acessar sua conta na plataforma e ver mais detalhes da
          sua reserva!
        </Text>
      </Content>

      <FormContent>
        <FormTitle>Efetuar login</FormTitle>
        <FormSubtitle>
          Preencha os campos abaixo para efetuar login
        </FormSubtitle>
        <Forms.Unform onSubmit={handleAuthenticate} ref={authFormRef}>
          <Forms.Grid>
            <ComponentInputSimple label="E-mail" name="email" />
          </Forms.Grid>
          <Forms.Grid>
            <ComponentInputPassword label="Senha" name="password" />
          </Forms.Grid>

          <Actions>
            <ComponentButtonBase
              height="2.5rem"
              isLoading={isLoadingSignIn}
              type="submit"
            >
              Entrar
            </ComponentButtonBase>
          </Actions>
        </Forms.Unform>
      </FormContent>

      <Observation>
        Caso você não tenha recebido o e-mail com mais informações da sua
        reserva ou não tenha sido você que efetuou a reserva, entre em contato
        com o responsável pelo evento para mais detalhes.
      </Observation>
    </Container>
  );
};

export default AlreadyBought;
