import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

export interface IChangePasswordRequestAction {
  data: {
    currentPassword: string;
    newPassword: string;
  };
  functions: {
    error(err: any): void;
    success(): void;
  };
}

function changePasswordRequest(
  draft: Draft<IAuthData>,
  _: PayloadAction<IChangePasswordRequestAction>,
) {
  draft.changePassword.config.isLoading = true;
}

export default changePasswordRequest;
