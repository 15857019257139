import cardNumber from './cardNumber';
import cpf from './cpf';
import date from './date';
import money from './money';
import onlyNumbers from './onlyNumbers';
import phone from './phone';
import postalCode from './postalCode';
import removeMask from './removeMask';

export default {
  cardNumber,
  cpf,
  date,
  money,
  onlyNumbers,
  phone,
  postalCode,
  removeMask,
};
