import { useRef } from 'react';

import ComponentInputBase, {
  IComponentInputBaseProps,
  IComponentInputBaseRefProps,
} from 'components/input/Base';

interface IComponentInputSimpleProps extends IComponentInputBaseProps {
  uppercase?: boolean;
}

const ComponentInputSimple: React.FC<IComponentInputSimpleProps> = ({
  uppercase,
  ...props
}) => {
  const componentInputBaseRef = useRef<IComponentInputBaseRefProps>(null);
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    let textFormatted = '';
    textFormatted = text;
    if (uppercase) {
      textFormatted = text.toUpperCase();
    }

    componentInputBaseRef.current?.setValueRef(textFormatted);
  };

  return (
    <ComponentInputBase
      {...props}
      onChange={handleOnChange}
      ref={componentInputBaseRef}
    />
  );
};

export default ComponentInputSimple;
