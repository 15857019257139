export default {
  background: '#F2F3F5',
  backgroundDark: '#d9dadb',
  boxShadow: 'rgba(0, 0, 0, 0.25)',

  black900: '#000000',

  darkBlue900: '#161150',

  darkGreen800: '#1B4E2B',
  darkGreen900: '#184225',

  gray100: '#DEDEDE',
  gray200: '#888888',
  gray500: '#666666',

  green100: '#E6F1EA',

  lightBlue900: '#44A0F4',

  orange700: '#E09D4E',
  orange900: '#C4771E',

  red700: '#D66565',

  white100: '#F8F8F8',
  white900: '#FFFFFF',

  transparent: 'transparent',
};
