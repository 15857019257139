import Tables from 'styles/tables';

import { MyEventsItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <MyEventsItemsHeader>
      <Tables.ItemHeader center>ID</Tables.ItemHeader>
      <Tables.ItemHeader>Nome do evento</Tables.ItemHeader>
      <Tables.ItemHeader>Data do evento</Tables.ItemHeader>
      <Tables.ItemHeader center>Ações</Tables.ItemHeader>
    </MyEventsItemsHeader>
  );
};

export default ItemHeader;
