import { createGlobalStyle } from 'styled-components';
import 'suneditor/dist/css/suneditor.min.css';

import colors from 'styles/colors';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    outline: none;
    -webkit-font-smoothing: antialiased !important;
  }
    html {
      @media screen and (max-width: 1440px) {
          font-size: 93.75%;
      }
      @media screen and (max-width: 720px) {
          font-size: 87.5%;
      }
      @media (min-width: 900px) {
        ::-webkit-scrollbar {
          background: ${colors.darkBlue900};
          height: 0.5rem;
          width: 0.4rem;
        }
        ::-webkit-scrollbar-button {
          height: 0;
          width: 0;
        }
        ::-webkit-scrollbar-track {
          background: ${colors.background};
        }
        ::-webkit-scrollbar-thumb {
          background: ${colors.darkBlue900};
          height: 0.5rem;
          width: 0.4rem;
          border-radius: 0 0 0.25rem 0.25rem;
        }
        body.transparentScrollBar {
          ::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
          ::-webkit-scrollbar-thumb {
            width: 0;
            height: 0;
          }
        }
        body.overflowHidden {
          overflow: hidden;
        }
      }
    }
  html, body, #root {
    min-height: 100vh;
  }
  body {
    background-color: ${colors.white900};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-rendering: optimizeLegibility;
    line-height: 1;
  }
  body, input, button, textarea {
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.gray500};
  }
  input, textarea {
    color: ${colors.gray500};
    &::placeholder {
      color: ${colors.gray200};
      font-size: 0.8rem;
    }
  }
  button {
    cursor: pointer;
    transition: all .3s ease;
  }
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease-in-out;
  }
  [disabled] {
    opacity: .6;
    cursor: not-allowed;
  }
  .ulPaginate {
    display: flex;
    list-style: none;
    margin: .5rem 0 0;
    padding: 0;
    align-items: center;
    column-gap: .25rem;
  }
  .ulPaginate li {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: ${colors.darkBlue900};
    cursor: pointer;
    font-size: .875rem;
    font-weight: 600;
    height: 1.75rem;
    transition: all .3s ease-in-out;
    width: 1.75rem;
    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    &.previous {
      font-size: 1.5rem;
      border: 1px solid ${colors.darkBlue900};
      border-radius: .5rem;
      font-weight: 400;
      width: 1.75rem;
      & a {
        width: 100%;
      }
    }
    &.next {
      font-size: 1.5rem;
      border: 1px solid ${colors.darkBlue900};
      border-radius: .5rem;
      font-weight: 400;
      width: 1.75rem;
    }
    :hover {
      opacity: .8
    }
    &.active {
      background: ${colors.darkBlue900};
      color: ${colors.white900};
      border-radius: .5rem;
      font-weight: 600;
    }
  }
`;
