import React, {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';

import { useField } from '@unform/core';

import ComponentIsVisible from 'components/utils/IsVisible';

import {
  Brand,
  CardFlag,
  Container,
  Error,
  FieldContainer,
  IFieldContainerProps,
  IInputProps,
  Input,
  Label,
  ToggleSecure,
} from './styles';

export interface IComponentInputBaseProps
  extends Omit<IFieldContainerProps, 'isErrored' | 'isFocused' | 'isFilled'>,
    IInputProps,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'height'> {
  cardFlag?: string;
  filter?: boolean;
  isLoading?: boolean;
  label?: string;
  name: string;
  secure?: boolean;
  title?: string;
}

export interface IComponentInputBaseRefProps {
  setValueRef(text: string): void;
}

const ComponentInputBase: React.ForwardRefRenderFunction<
  IComponentInputBaseRefProps,
  IComponentInputBaseProps
> = (
  {
    backgroundColor,
    cardFlag,
    height,
    isCard = false,
    isLoading = false,
    label,
    name,
    onChange,
    readOnly = false,
    secure = false,
    textAlign,
    title,
    type = 'text',
    ...rest
  },
  ref,
) => {
  const [inputType, setInputType] = useState<string>(type);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { defaultValue, error, fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (inputRef.current) {
      inputRef.current.value = text;
    }
    if (onChange) {
      onChange(event);
    }
  };
  const handleInputFocus = () => {
    setIsFocused(true);
  };
  const handleInputBlur = () => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  };
  const handleToggleSecure = () => {
    setInputType(currentType => {
      if (currentType === 'password') {
        return 'text';
      }
      return 'password';
    });
  };

  useImperativeHandle(ref, () => ({
    setValueRef: (text: string) => {
      if (inputRef.current) {
        inputRef.current.value = text;
      }
    },
  }));

  return (
    <Container>
      <ComponentIsVisible when={!!label}>
        <Label>{label}</Label>
      </ComponentIsVisible>

      <FieldContainer
        backgroundColor={backgroundColor}
        height={height}
        isCard={isCard}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isReadOnly={readOnly}
        isSecure={secure}
      >
        <Input
          defaultValue={defaultValue}
          isLoading={isLoading}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          readOnly={readOnly || isLoading}
          ref={inputRef}
          textAlign={textAlign}
          title={title}
          type={inputType}
          {...rest}
        />

        <ComponentIsVisible when={secure}>
          <ComponentIsVisible when={inputType === 'password'}>
            <ToggleSecure
              onClick={handleToggleSecure}
              tabIndex={-1}
              type="button"
            >
              <FiEye size={18} />
            </ToggleSecure>
          </ComponentIsVisible>
          <ComponentIsVisible when={inputType !== 'password'}>
            <ToggleSecure
              onClick={handleToggleSecure}
              tabIndex={-1}
              type="button"
            >
              <FiEyeOff size={18} />
            </ToggleSecure>
          </ComponentIsVisible>
        </ComponentIsVisible>

        <ComponentIsVisible when={isCard}>
          <CardFlag>
            <ComponentIsVisible when={!!cardFlag}>
              <Brand alt="Bandeira do cartão" src={cardFlag} />
            </ComponentIsVisible>
          </CardFlag>
        </ComponentIsVisible>

        <ComponentIsVisible when={!!error && !readOnly}>
          <Error title={error as string}>
            <FiAlertCircle size={18} />
          </Error>
        </ComponentIsVisible>
      </FieldContainer>
    </Container>
  );
};

export default forwardRef(ComponentInputBase);
