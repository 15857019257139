import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

export interface ISetProfileAction {
  email: string;
  id: number;
  name: string;
}

function setProfile(
  draft: Draft<IAuthData>,
  action: PayloadAction<ISetProfileAction>,
) {
  draft.profile.id = action.payload.id;
  draft.profile.email = action.payload.email;
  draft.profile.name = action.payload.name;
}

export default setProfile;
