import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface IAuthDataAuthorization {
  token: string | null;
}

export interface IAuthData {
  authorization: IAuthDataAuthorization;
  changePassword: {
    config: {
      isLoading: boolean;
    };
  };
  config: {
    isLoading: boolean;
  };
  firstAccess: {
    config: {
      isLoading: boolean;
    };
  };
  forgotPassword: {
    config: { isLoading: boolean };
  };
  profile: {
    email: string | null;
    id: number | null;
    name: string | null;
  };
  resetPassword: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IAuthData = {
  authorization: { token: null },
  changePassword: {
    config: {
      isLoading: false,
    },
  },
  config: {
    isLoading: false,
  },
  firstAccess: {
    config: {
      isLoading: false,
    },
  },
  forgotPassword: {
    config: {
      isLoading: false,
    },
  },
  profile: {
    email: null,
    id: null,
    name: null,
  },
  resetPassword: {
    config: {
      isLoading: false,
    },
  },
};

const authSlice = createSlice({
  name: '@auth',
  initialState,
  reducers,
});

export const authActions = authSlice.actions;
export const authReducers = authSlice.reducer;
