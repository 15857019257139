import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const Backdrop = styled.div`
  background: ${colors.boxShadow};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0s cubic-bezier(0.23, 1, 0.32, 1);
  width: 100vw;
  z-index: 5;
`;
