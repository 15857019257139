import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { pixActions } from '../index';
import checkIsPaid from './checkIsPaid';
import create from './create';

const pixSagas = all([
  takeLatest(pixActions.createRequest, create),
  takeEvery(pixActions.checkIsPaidRequest, checkIsPaid),
]);

export default pixSagas;
