import { useRef, useState } from 'react';

import cardValidator from 'card-validator';
import formatters from 'formatters';

import cardBrands from 'constants/cardBrands';

import ComponentInputBase, {
  IComponentInputBaseProps,
  IComponentInputBaseRefProps,
} from 'components/input/Base';

type IComponentInputCardProps = IComponentInputBaseProps;

const ComponentInputCard: React.FC<IComponentInputCardProps> = props => {
  const [brandImage, setBrandImage] = useState<string>(cardBrands.default);

  const componentInputBaseRef = useRef<IComponentInputBaseRefProps>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const textFormatted = formatters.cardNumber(text);
    componentInputBaseRef.current?.setValueRef(textFormatted);

    if (textFormatted.length >= 17) {
      const cardValidation = cardValidator.number(text);

      if (!cardValidation.card) {
        setBrandImage(cardBrands.default);
        return;
      }

      const cardType = cardValidation.card?.type;

      const cardBrandSelected = cardBrands[cardType] || cardBrands.default;

      setBrandImage(cardBrandSelected);
    } else {
      setBrandImage(cardBrands.default);
    }
  };

  return (
    <ComponentInputBase
      {...props}
      cardFlag={brandImage}
      isCard
      maxLength={19}
      onChange={handleOnChange}
      ref={componentInputBaseRef}
    />
  );
};

export default ComponentInputCard;
