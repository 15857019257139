import checkIsPaidFailure from './checkIsPaid/failure';
import checkIsPaidRequest from './checkIsPaid/request';
import checkIsPaidSuccess from './checkIsPaid/success';
import createFailure from './create/failure';
import createRequest from './create/request';
import createSuccess from './create/success';

const reducers = {
  checkIsPaidFailure,
  checkIsPaidRequest,
  checkIsPaidSuccess,
  createFailure,
  createRequest,
  createSuccess,
};

export default reducers;
