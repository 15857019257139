import { Route, Routes } from 'react-router-dom';

import pages from 'constants/pages';

import Auth from 'pages/Auth';
import ResetPassword from 'pages/Auth/ResetPassword';
import AlreadyBought from 'pages/Event/AlreadyBought';
import EventDetails from 'pages/Event/Details';
import EventNotFound from 'pages/Event/NotFound';
import EventSuccess from 'pages/Event/Success';
import MyEventDetails from 'pages/Events/Details';
import MyEvents from 'pages/Events/index';
import MyEventVouchers from 'pages/Events/Vouchers';
import MyEventVoucherDetails from 'pages/Events/Vouchers/Details';
import LgpdPolitic from 'pages/LgpdPolitic';
import MyAccount from 'pages/MyAccount';
import ChangePassword from 'pages/MyAccount/ChangePassword';
import Terms from 'pages/Terms';

import NotFound from './NotFound';

const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Auth />} path={pages.auth} />
      <Route element={<ResetPassword />} path={pages.resetPassword} />
      <Route element={<EventDetails />} path="/evento/:slug" />
      <Route element={<EventNotFound />} path={pages.eventNotFound} />
      <Route element={<AlreadyBought />} path={pages.reservationAlreadyMade} />
      <Route element={<Terms />} path={pages.terms} />
      <Route element={<LgpdPolitic />} path={pages.lgpdPolitic} />
      <Route element={<EventSuccess />} path={pages.success} />
      <Route element={<MyAccount />} path={pages.myAccount} />
      <Route element={<MyEvents />} path={pages.myEvent.list} />
      <Route element={<MyEventVouchers />} path="/minha-conta/:id/vouchers" />
      <Route element={<ChangePassword />} path="/minha-conta/alterar-senha" />
      <Route
        element={<MyEventVoucherDetails />}
        path="/minha-conta/vouchers/:id/detalhes"
      />
      <Route element={<MyEventDetails />} path="/minha-conta/:id/vouchers" />
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default MainRoutes;
