import styled from 'styled-components';

import colors from 'styles/colors';

const Root = styled.div`
  display: flex;
  display: grid;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
`;

const Main = styled.main``;

const Global = styled.div`
  background-color: ${colors.white900};
  margin: 0.5rem auto 3rem;
  max-width: 70rem;
  min-height: calc(100vh - 18.5rem);
  padding: 2rem;
  width: 100%;
  @media screen and (min-width: 1440px) {
    max-width: 75rem;
    padding: 1rem;
  }
`;

export default {
  Root,
  Main,
  Global,
};
