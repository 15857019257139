import styled from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';

interface IContentProps {
  maxWidth?: number;
}

export const Content = styled.div.attrs({
  id: 'modalContent',
})<IContentProps>`
  animation-delay: 0.1s;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-name: ${animations.appear};
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: ${colors.white900};
  border-radius: 0.5rem;
  max-height: 90%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 28)}rem;
  overflow-y: auto;
  padding: 2rem;
  position: relative;
  width: 100%;
  z-index: 6;
  &.hideModal {
    animation-name: ${animations.disappear};
  }
`;

export const Title = styled.h4`
  color: ${colors.orange700};
  font-size: 1.2rem;
  font-weight: 600;
`;

export const Subtitle = styled.p`
  color: ${colors.gray200};
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0.25rem 0 1.25rem;
`;

export const CloseButton = styled.button`
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.gray500};
  font-size: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
  &:hover {
    opacity: 0.6;
  }
`;

interface IContentActionsProps {
  width?: number;
}

export const ContentActions = styled.div<IContentActionsProps>`
  align-items: center;
  column-gap: 0.5rem;
  display: flex;
  justify-content: center;
  margin: 1rem auto 0;
  width: ${props => (props.width ? props.width : 100)}%;
`;
