import downloadTicket from './downloadTicket';
import purchaseFailure from './purchase/failure';
import purchaseRequest from './purchase/request';
import purchaseSuccess from './purchase/success';

const reducers = {
  downloadTicket,
  purchaseFailure,
  purchaseRequest,
  purchaseSuccess,
};

export default reducers;
