import { Container, Message, Warning } from './styles';

const NotFound: React.FC = () => {
  return (
    <Container>
      <Warning>Erro 404</Warning>
      <Message>
        Este evento não existe ou não foi encontrado, por favor verifique seu
        e-mail novamente.
      </Message>
    </Container>
  );
};

export default NotFound;
