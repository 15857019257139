import styled, { css } from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Details = styled.div`
  width: 100%;
`;

export const DetailsHeader = styled.div`
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 35rem;
  width: 100%;
`;

export const Name = styled.div`
  display: grid;
  row-gap: 0.25rem;
`;

interface INameValueProps {
  isLoading?: boolean;
}

export const NameValue = styled.h4<INameValueProps>`
  color: ${colors.orange700};
  font-size: 1.25rem;
  font-weight: 600;
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 1.5rem;
      width: 100%;
    `}
`;

export const Small = styled.small`
  color: ${colors.darkBlue900};
  font-size: 0.875rem;
  font-weight: 400;
`;

export const Content = styled.div`
  column-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: fit-content;
  width: 100%;
`;

export const Hr = styled.hr`
  background-color: ${colors.gray100};
  border: 0;
  box-shadow: none;
  grid-column: 1 / 3;
  height: 1px;
  margin: 1.5rem 0;
  width: 100%;
`;

export const Subtitle = styled.h5`
  color: ${colors.orange700};
  font-size: 0.875rem;
  font-weight: 500;
  grid-column: 1 / 3;
`;

export const Info = styled.div`
  display: grid;
  margin-top: 1rem;
  row-gap: 0.375rem;
`;

interface IValueProps {
  capitalize?: boolean;
  isLoading?: boolean;
}

export const Value = styled.p<IValueProps>`
  color: ${colors.gray500};
  font-size: 0.925rem;
  font-weight: 500;
  text-transform: ${props => (props.capitalize ? 'capitalize' : 'initial')};
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 0.875rem;
      width: 100%;
    `}
`;
