import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { FormHandles, Scope } from '@unform/core';
import cardValidator from 'card-validator';
import formatters from 'formatters';
import helpers from 'helpers';
import IAddressFieldsReadOnly from 'interfaces/IAddressFieldsReadOnly';
import IAddress from 'models/IAddress';
import { EEventPaymentMethodType, IEventPaymentMethod } from 'models/IEvent';
import validators from 'validators';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputCard from 'components/input/Card';
import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentInputCpf from 'components/input/Cpf';
import ComponentInputNumber from 'components/input/Number';
import ComponentInputPhone from 'components/input/Phone';
import ComponentInputPostalCode from 'components/input/PostalCode';
import ComponentSelectSimple, {
  IOptionTypeBase,
} from 'components/input/select/Simple';
import ComponentInputSimple from 'components/input/Simple';
import ComponentMenu from 'components/Menu';
import ComponentIsVisible from 'components/utils/IsVisible';

import { eventActions } from 'store/slices/event';
import eventSelectors from 'store/slices/event/selectors';
import { orderActions } from 'store/slices/order';
import orderSelectors from 'store/slices/order/selectors';
import { pixActions } from 'store/slices/pix';
import pixSelectors from 'store/slices/pix/selectors';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import Forms from 'styles/forms';
import General from 'styles/general';

import {
  Card,
  Container,
  Content,
  Day,
  Details,
  Grid,
  Image,
  Info,
  Item,
  Label,
  Line,
  Location,
  Main,
  Name,
  PaymentQrCodeButton,
  PaymentText,
  Price,
  QrCodeContainer,
  QrCodeImage,
  Small,
  Subtitle,
  TermsContent,
  TermsMessage,
  Thumbnail,
  Ticket,
  Value,
} from './styles';

interface ICardData {
  expiration: {
    month: string;
    year: string;
  };
  holder: string;
  number: string;
  securityCode: string;
}

interface IPriceType {
  installment: number;
  noInstallment: number;
}

const schemaValidation = Yup.object().shape({
  responsible: Yup.object().shape({
    document: Yup.string()
      .trim()
      .test('Cpf', 'CPF inválido (ex: 111.111.111-11)', cpf => {
        if (!cpf) {
          return true;
        }
        if (cpf.length <= 14) {
          return validators.cpf(cpf);
        }
        return true;
      })
      .required('Informe o CPF'),
    phone: Yup.string()
      .required('Informe o telefone')
      .min(14, 'Tamanho mínimo do campo deve ser 14 caracteres')
      .max(15, 'Tamanho máximo do campo deve ser 15 caracteres'),
  }),
  address: Yup.object().shape({
    postalCode: Yup.string().trim().required('Informe o CEP'),
    street: Yup.string().trim().required('Informe o logradouro'),
    number: Yup.string().trim().required('Informe o nº'),
    neighborhood: Yup.string().trim().required('Informe o bairro'),
    city: Yup.string().trim().required('Informe a cidade'),
    state: Yup.string()
      .oneOf(
        [
          'AC',
          'AL',
          'AP',
          'AM',
          'BA',
          'CE',
          'DF',
          'ES',
          'GO',
          'MA',
          'MT',
          'MS',
          'MG',
          'PA',
          'PB',
          'PR',
          'PE',
          'PI',
          'RJ',
          'RN',
          'RS',
          'RO',
          'RR',
          'SC',
          'SP',
          'SE',
          'TO',
        ],
        "Campo 'UF' inválido",
      )
      .required('Informe o estado')
      .max(2, "Tamanho máximo do campo 'UF' deve ser 2 caracteres"),
  }),
  card: Yup.object().shape({
    number: Yup.string().when('$type', {
      is: (type: EEventPaymentMethodType) =>
        type === EEventPaymentMethodType.CreditCard ||
        type === EEventPaymentMethodType.DebitCard,
      then: Yup.string()
        .test(
          'cardNumberValid',
          'Digite um número de cartão de crédito válido',
          cardNumber => {
            const cardNumberValid = cardValidator.number(cardNumber);

            return cardNumberValid.isValid;
          },
        )
        .test('CardNumberBrand', 'Bandeira não aceita', cardNumber => {
          const cardNumberValid = cardValidator.number(cardNumber);

          const cardNumberBrand = cardNumberValid.card?.type;

          if (
            cardNumberBrand !== 'american-express' &&
            cardNumberBrand !== 'elo' &&
            cardNumberBrand !== 'hipercard' &&
            cardNumberBrand !== 'mastercard' &&
            cardNumberBrand !== 'visa'
          ) {
            return false;
          }

          return true;
        })
        .required('Informe o número do cartão'),
    }),
    holder: Yup.string().when('$type', {
      is: (type: EEventPaymentMethodType) =>
        type === EEventPaymentMethodType.CreditCard ||
        type === EEventPaymentMethodType.DebitCard,
      then: Yup.string().required('Informe o nome impresso no cartão'),
    }),
    expiration: Yup.object().shape({
      month: Yup.string().when('$type', {
        is: (type: EEventPaymentMethodType) =>
          type === EEventPaymentMethodType.CreditCard ||
          type === EEventPaymentMethodType.DebitCard,
        then: Yup.string().max(2).required('Informe o mês de validade'),
      }),
      year: Yup.string().when('$type', {
        is: (type: EEventPaymentMethodType) =>
          type === EEventPaymentMethodType.CreditCard ||
          type === EEventPaymentMethodType.DebitCard,
        then: Yup.string().min(4).max(4).required('Informe o ano de validade'),
      }),
    }),
    securityCode: Yup.string().when('$type', {
      is: (type: EEventPaymentMethodType) =>
        type === EEventPaymentMethodType.CreditCard ||
        type === EEventPaymentMethodType.DebitCard,
      then: Yup.string()
        .min(3)
        .max(4)
        .required('Informe o código de segurança'),
    }),
    installments: Yup.object().when('$type', {
      is: (type: EEventPaymentMethodType) =>
        type === EEventPaymentMethodType.CreditCard,
      then: Yup.object()
        .shape({
          value: Yup.number(),
        })
        .typeError('Selecione a parcela')
        .required('Selecione a parcela'),
    }),
  }),
  installments: Yup.object().when('$type', {
    is: (type: EEventPaymentMethodType) =>
      type === EEventPaymentMethodType.Ticket,
    then: Yup.object()
      .shape({
        value: Yup.number(),
      })
      .typeError('Selecione a parcela')
      .required('Selecione a parcela'),
  }),
});

function formatDateEventFull(date: Date): string {
  return `${helpers.getDayWeekFull(date)}, ${helpers.formatDayEvent(
    date,
  )} de ${helpers.getMonthFull(date)} de ${date.getFullYear()}`;
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const EventDetails: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const query = useQuery();
  const reduxDispatch = useReduxDispatch();
  const { slug } = useParams();
  const [pixResponse, setPixResponse] = useState<{
    MerchantOrderId: string;
    Payment: {
      PaymentId: string;
      QrCodeBase64Image: string;
      QrCodeString: string;
      Status: number;
    };
    orderId: number;
  } | null>(null);

  const eventInfo = useReduxSelector(eventSelectors.getInfoData);
  const eventInfoIsLoading = useReduxSelector(eventSelectors.getInfoIsLoading);
  const isLoading = useReduxSelector(orderSelectors.isLoading);
  const isLoadingPix = useReduxSelector(pixSelectors.isLoading);

  const token = query.get('token') as string | undefined;

  const formRef = useRef<FormHandles>(null);

  const [adultPrice, setAdultPrice] = useState<IPriceType>({
    installment: 0,
    noInstallment: 0,
  });
  const [installmentsNumber, setInstallmentsNumber] = useState<number>(1);
  const [installments, setInstallments] = useState<
    { label: string; value: number }[]
  >([]);
  const [, setQuantityInstallments] = useState<number>(1);
  const [addressReadOnly, setAddressReadOnly] =
    useState<IAddressFieldsReadOnly>({
      city: false,
      neighborhood: false,
      state: false,
      street: false,
    });
  const [paymentMethodType, setPaymentMethodType] =
    useState<EEventPaymentMethodType | null>(null);
  const [paymentMethodId, setPaymentMethodId] = useState<number | null>(null);

  useEffect(() => {
    if (!token) {
      toast.show({
        title: 'Falha ao carregar evento',
        type: 'error',
      });
      navigate(pages.eventNotFound, { replace: true });
      return;
    }

    const eventId = Number(slug?.split('-').shift());
    reduxDispatch(
      eventActions.getInfoRequest({
        data: {
          eventId,
          responsibleToken: token,
        },
        functions: {
          notFound: () => {
            navigate(pages.eventNotFound, { replace: true });
          },
          alreadyBought: () => {
            navigate(pages.reservationAlreadyMade, { replace: true });
          },
        },
      }),
    );
  }, [reduxDispatch, navigate, slug, toast, token]);

  const calculateInstallments = useCallback(
    ({
      qtyInstallments,
      totalOrder,
      totalOrderWithInstallment,
    }: {
      qtyInstallments: number;
      totalOrder: number;
      totalOrderWithInstallment: number;
    }) => {
      const installments: { label: string; value: number }[] = [];
      for (let index = 1; index <= qtyInstallments; index += 1) {
        let installmentAmount = '';
        if (index === 1) {
          installmentAmount = formatters.money(totalOrder / index, {
            addPrefix: true,
          });
        } else {
          installmentAmount = `${formatters.money(
            totalOrderWithInstallment / index,
            {
              addPrefix: true,
            },
          )} - (valor parcelado)`;
        }
        installments.push({
          label: `${index}x de ${installmentAmount}`,
          value: index,
        });
      }
      setInstallments(installments);
      setTimeout(() => {
        formRef.current?.clearField('card.installments');
      }, 500);
    },
    [],
  );

  const handleSetFieldsAddress = useCallback((address: IAddress) => {
    formRef.current?.setFieldValue(
      'address.street',
      address.street.toUpperCase(),
    );
    formRef.current?.setFieldValue(
      'address.neighborhood',
      address.neighborhood.toUpperCase(),
    );
    formRef.current?.setFieldValue('address.city', address.city.toUpperCase());
    formRef.current?.setFieldValue(
      'address.state',
      address.state.toUpperCase(),
    );
    setAddressReadOnly({
      street: !!address.street,
      neighborhood: !!address.neighborhood,
      city: !!address.city,
      state: !!address.state,
    });
  }, []);

  const handleChangePaymentType = useCallback(
    event => {
      const eventSelected = event as IOptionTypeBase<IEventPaymentMethod>;
      const adultAmountNoInstallment = eventSelected.additional?.amount.adult
        .noInstallment as number;
      const adultAmountInstallment = eventSelected.additional?.amount.adult
        .installment as number;
      setAdultPrice({
        noInstallment: adultAmountNoInstallment,
        installment: adultAmountInstallment,
      });
      setPaymentMethodType(eventSelected?.additional?.type || null);
      setPaymentMethodId(eventSelected.additional?.id || null);
      setQuantityInstallments(
        eventSelected.additional?.quantityInstallments || 1,
      );
      const totalOrder = 1 * adultAmountNoInstallment;
      const totalOrderWithInstallment = 1 * adultAmountInstallment;

      let qtyInstallments = eventSelected.additional?.quantityInstallments || 1;
      if (
        !!eventSelected.additional?.quantityInstallments &&
        !eventInfo?.allowInstallmentsAfter
      ) {
        if (!eventInfo) {
          return;
        }
        const currentDate = new Date();
        let months = 0;
        months =
          (eventInfo.date.getFullYear() - currentDate.getFullYear()) * 12;
        months -= currentDate.getMonth();
        months += eventInfo.date.getMonth();
        const result = months <= 0 ? 0 : months;
        if (result < eventSelected.additional.quantityInstallments) {
          qtyInstallments = result;
        }
      }
      calculateInstallments({
        qtyInstallments,
        totalOrder,
        totalOrderWithInstallment,
      });
    },
    [calculateInstallments, eventInfo],
  );

  const handleChangeInstallment = useCallback(event => {
    if (!event) {
      return;
    }
    setInstallmentsNumber(event.value);
  }, []);

  const handlePurchaseEvent = useCallback(
    async data => {
      try {
        if (!eventInfo) {
          return;
        }
        if (!token) {
          toast.show({
            title: 'Atenção',
            description:
              'Token do responsável não encontrado, por favor acesse o link pelo e-mail novamente',
            type: 'info',
          });
          return;
        }
        if (!data.acceptTerms) {
          toast.show({
            title: 'Atenção',
            description: 'Aceite os termos de uso',
            type: 'info',
          });
          return;
        }
        if (!paymentMethodId) {
          toast.show({
            title: 'Atenção',
            description: 'Selecione uma forma de pagamento',
            type: 'info',
          });
          return;
        }

        formRef.current?.setErrors({});
        await schemaValidation.validate(data, {
          abortEarly: false,
          context: { type: paymentMethodType },
        });
        let cardData: ICardData | undefined;

        if (
          paymentMethodType === EEventPaymentMethodType.CreditCard ||
          paymentMethodType === EEventPaymentMethodType.DebitCard
        ) {
          cardData = data.card;
        }

        const amountChildrenFormatted =
          installmentsNumber === 1
            ? adultPrice.noInstallment
            : adultPrice.installment;

        if (paymentMethodType !== EEventPaymentMethodType.Pix) {
          reduxDispatch(
            orderActions.purchaseRequest({
              data: {
                address: data.address,
                responsible: {
                  document: data.responsible.document,
                  phone: data.responsible.phone,
                  token,
                },
                amount: 1 * amountChildrenFormatted,
                eventId: eventInfo.id,
                installments: cardData
                  ? data.card.installments?.value
                  : data?.installments?.value || 1,
                paymentMethodId,
                paymentMethodType: paymentMethodType as EEventPaymentMethodType,
                card: cardData,
              },
              functions: {
                error: err => {
                  helpers.errorHandling(err);
                },
                success: (): void => {
                  navigate(pages.success, {
                    state: {
                      responsibleEmail: eventInfo.participant.responsible.email,
                      paymentMethodType,
                      eventName: eventInfo.name,
                    },
                    replace: true,
                  });
                },
              },
            }),
          );
        }
        if (paymentMethodType === EEventPaymentMethodType.Pix) {
          reduxDispatch(
            pixActions.createRequest({
              data: {
                address: data.address,
                responsible: {
                  document: data.responsible.document,
                  phone: data.responsible.phone,
                  token,
                },
                amount: 1 * amountChildrenFormatted,
                eventId: eventInfo.id,
                installments: cardData
                  ? data.card.installments?.value
                  : data?.installments?.value || 1,
                paymentMethodId,
                paymentMethodType: paymentMethodType as EEventPaymentMethodType,
              },
              functions: {
                error: (err: any) => {
                  helpers.errorHandling(err);
                },
                success: (data): void => {
                  const dataParsed = JSON.parse(data.response);
                  setPixResponse({
                    orderId: data.orderId,
                    MerchantOrderId: dataParsed.MerchantOrderId,
                    Payment: {
                      PaymentId: dataParsed.Payment.PaymentId,
                      QrCodeBase64Image: dataParsed.Payment.QrCodeBase64Image,
                      QrCodeString: dataParsed.Payment.QrCodeString,
                      Status: dataParsed.Payment.Status,
                    },
                  });
                },
              },
            }),
          );
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = helpers.getValidationErrors(err);
          formRef.current?.setErrors(validationErrors);
        }
      }
    },
    [
      adultPrice.installment,
      adultPrice.noInstallment,
      eventInfo,
      installmentsNumber,
      navigate,
      paymentMethodId,
      paymentMethodType,
      reduxDispatch,
      toast,
      token,
    ],
  );

  const checkPixIsPaid = useCallback(() => {
    if (!pixResponse?.MerchantOrderId || !eventInfo || !token) {
      return;
    }
    reduxDispatch(
      pixActions.checkIsPaidRequest({
        data: {
          eventId: eventInfo.id,
          orderId: pixResponse.orderId,
          paymentId: pixResponse.Payment.PaymentId,
          responsibleToken: token,
        },
        functions: {
          success: (): void => {
            navigate(pages.success, {
              state: {
                responsibleEmail: eventInfo.participant.responsible.email,
                paymentMethodType,
                eventName: eventInfo?.name,
              },
              replace: true,
            });
          },
        },
      }),
    );
  }, [
    eventInfo,
    navigate,
    paymentMethodType,
    pixResponse,
    reduxDispatch,
    token,
  ]);

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    checkPixIsPaid();
  }, [checkPixIsPaid]);

  return (
    <Main>
      <ComponentMenu />
      <Containers.Global>
        <General.PageTitleContainer>
          <General.PageTitle>Finalizar reserva</General.PageTitle>
        </General.PageTitleContainer>

        <Container onSubmit={handlePurchaseEvent} ref={formRef}>
          <Content gridArea="event">
            <Subtitle>Evento selecionado</Subtitle>
            <Thumbnail isLoading={eventInfoIsLoading}>
              <ComponentIsVisible when={!!eventInfo}>
                <Image
                  alt={`Banner ${eventInfo?.name}`}
                  src={eventInfo?.picture as string}
                />
              </ComponentIsVisible>
            </Thumbnail>
            <Info>
              <Day isLoading={eventInfoIsLoading}>
                <Small isLoading={eventInfoIsLoading}>
                  {eventInfo?.date && helpers.getDayWeek(eventInfo.date)}
                </Small>
                {eventInfo?.date && helpers.formatDayEvent(eventInfo.date)}
              </Day>
              <Details>
                <Name isLoading={eventInfoIsLoading}>{eventInfo?.name}</Name>
                <Location isLoading={eventInfoIsLoading}>
                  {eventInfo?.location &&
                    helpers.formatLocation(eventInfo.location)}
                </Location>
              </Details>
            </Info>
          </Content>

          <Content gridArea="terms">
            <Subtitle>Termos e condições da reserva</Subtitle>
            <Card>
              <TermsContent
                className="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"
                dangerouslySetInnerHTML={{ __html: eventInfo?.terms || '' }}
              />

              <Line />

              <ComponentInputCheckbox
                label="Li, aceito e concordo com os termos e condições da reserva"
                name="acceptTerms"
              />
            </Card>
          </Content>

          <Content gridArea="details">
            <Subtitle>Detalhes do evento</Subtitle>
            <Card>
              <Grid>
                <Item>
                  <Label>Data do evento</Label>
                  <Value isLoading={eventInfoIsLoading}>
                    {eventInfo?.date && formatDateEventFull(eventInfo.date)}
                  </Value>
                </Item>

                <Item>
                  <Label>Classificação etária</Label>
                  <Value isLoading={eventInfoIsLoading}>
                    {eventInfo?.age.min
                      ? `Acima de ${eventInfo?.age.min} anos`
                      : 'Livre'}
                  </Value>
                </Item>
                <Item>
                  <Label>Idade máxima</Label>
                  <Value isLoading={eventInfoIsLoading}>
                    {eventInfo?.age.max
                      ? `Até ${eventInfo?.age.max} anos`
                      : 'Livre'}
                  </Value>
                </Item>
                <Item>
                  <Label>Limite de ingressos</Label>
                  <Value isLoading={eventInfoIsLoading}>
                    {eventInfo?.restriction.purchase
                      ? `Apenas ${eventInfo?.quantityPurchase} ingresso(s) por CPF`
                      : 'Sem limite de ingressos'}
                  </Value>
                </Item>
              </Grid>
            </Card>
          </Content>

          <Content gridArea="participants">
            <Subtitle>Dados da reserva</Subtitle>
            <Card>
              <Item>
                <Label>Participante</Label>
                <Value
                  isLoading={eventInfoIsLoading}
                  isParticipant={!!eventInfo?.participant}
                >
                  {`${eventInfo?.participant.name} - ${eventInfo?.participant.age} anos - ${eventInfo?.participant.gender}`}
                </Value>
              </Item>
              <Item>
                <Label marginTop={1}>Responsável</Label>
                <Value isLoading={eventInfoIsLoading}>
                  {`${eventInfo?.participant.responsible.name} - ${eventInfo?.participant.responsible.email}`}
                </Value>
              </Item>
            </Card>
          </Content>

          <Content gridArea="payment" overflow="visible">
            <Subtitle>Pagamento</Subtitle>
            <Card>
              <Label marginBottom={0.5}>Dados da cobrança</Label>
              <Scope path="responsible">
                <Forms.Grid gridTemplateColumns="repeat(2, 1fr)">
                  <ComponentInputCpf label="CPF" name="document" />
                  <ComponentInputPhone label="Telefone" name="phone" />
                </Forms.Grid>
              </Scope>
              <Scope path="address">
                <Forms.Grid gridTemplateColumns="7rem 1fr 4rem">
                  <ComponentInputPostalCode
                    label="CEP"
                    name="postalCode"
                    onSetFieldsAddress={handleSetFieldsAddress}
                  />
                  <ComponentInputSimple
                    label="Logradouro"
                    name="street"
                    readOnly={addressReadOnly.street}
                  />
                  <ComponentInputSimple label="Nº" name="number" />
                </Forms.Grid>
                <Forms.Grid gridTemplateColumns="10rem 1fr">
                  <ComponentInputSimple label="Complemento" name="complement" />
                  <ComponentInputSimple
                    label="Bairro"
                    name="neighborhood"
                    readOnly={addressReadOnly.neighborhood}
                  />
                </Forms.Grid>
                <Forms.Grid gridTemplateColumns="1fr 4rem">
                  <ComponentInputSimple
                    label="Cidade"
                    name="city"
                    readOnly={addressReadOnly.city}
                  />
                  <ComponentInputSimple
                    label="UF"
                    maxLength={2}
                    name="state"
                    readOnly={addressReadOnly.state}
                  />
                </Forms.Grid>
              </Scope>
              <ComponentSelectSimple
                label="Selecione o método de pagamento"
                name="paymentMethod"
                onChange={handleChangePaymentType}
                options={eventInfo?.paymentMethods.map(paymentMethod => ({
                  label: paymentMethod.name,
                  value: paymentMethod.id,
                  additional: paymentMethod,
                }))}
                placeholder="Selecione a forma de pagamento"
              />

              <Grid informative>
                <Ticket>1x ingresso(s) infantil</Ticket>
                <Price informative>
                  <ComponentIsVisible when={installmentsNumber === 1}>
                    {formatters.money(adultPrice.noInstallment, {
                      addPrefix: true,
                    })}
                  </ComponentIsVisible>
                  <ComponentIsVisible when={installmentsNumber > 1}>
                    {formatters.money(adultPrice.installment, {
                      addPrefix: true,
                    })}
                  </ComponentIsVisible>
                </Price>
                <Ticket total>Total</Ticket>
                <Price total>
                  <ComponentIsVisible when={installmentsNumber === 1}>
                    {formatters.money(1 * adultPrice.noInstallment, {
                      addPrefix: true,
                    })}
                  </ComponentIsVisible>
                  <ComponentIsVisible when={installmentsNumber > 1}>
                    {formatters.money(1 * adultPrice.installment, {
                      addPrefix: true,
                    })}
                  </ComponentIsVisible>
                </Price>
              </Grid>

              <ComponentIsVisible
                when={paymentMethodType === EEventPaymentMethodType.Ticket}
              >
                <ComponentSelectSimple
                  label="Selecione o nº de parcelas"
                  name="installments"
                  onChange={handleChangeInstallment}
                  options={installments}
                  placeholder="Selecione o nº de parcelas"
                />
              </ComponentIsVisible>

              <ComponentIsVisible
                when={
                  paymentMethodType === EEventPaymentMethodType.CreditCard ||
                  paymentMethodType === EEventPaymentMethodType.DebitCard
                }
              >
                <Line />

                <Label marginBottom={0.5}>Dados do cartão</Label>
                <Scope path="card">
                  <Forms.Grid gridTemplateColumns="repeat(2, 1fr)">
                    <ComponentInputCard label="Nº do cartão" name="number" />
                    <ComponentInputSimple
                      label="Nome impresso no cartão"
                      name="holder"
                      uppercase
                    />
                  </Forms.Grid>
                  <Forms.Grid
                    gridTemplateColumns="repeat(3, .25fr) 1fr"
                    gridTemplateColumnsMobile="repeat(3, 1fr)"
                  >
                    <Scope path="expiration">
                      <ComponentInputNumber
                        label="Mês"
                        maxLength={2}
                        name="month"
                        placeholder="MM"
                      />
                      <ComponentInputNumber
                        label="Ano"
                        maxLength={4}
                        name="year"
                        placeholder="AAAA"
                      />
                    </Scope>
                    <ComponentInputNumber
                      label="CVV"
                      maxLength={4}
                      name="securityCode"
                    />
                    <ComponentIsVisible
                      when={
                        paymentMethodType === EEventPaymentMethodType.CreditCard
                      }
                    >
                      <ComponentSelectSimple
                        gridColumn="1/4"
                        label="Selecione o nº de parcelas"
                        name="installments"
                        onChange={handleChangeInstallment}
                        options={installments}
                        placeholder="Selecione o nº de parcelas"
                      />
                    </ComponentIsVisible>
                  </Forms.Grid>
                </Scope>
              </ComponentIsVisible>

              <ComponentIsVisible when={!!pixResponse}>
                <QrCodeContainer>
                  <PaymentText>
                    Escaneie e o QR Code pelo seu celular e efetue o pagamento
                  </PaymentText>
                  <QrCodeImage
                    alt="QR Code"
                    src={`data:image/jpeg;base64,${pixResponse?.Payment.QrCodeBase64Image}`}
                  />
                  <PaymentQrCodeButton
                    onClick={() => {
                      navigator.clipboard.writeText(
                        pixResponse?.Payment.QrCodeString as string,
                      );
                      toast.show({
                        title: 'Pix copiado para a área de transferência',
                        type: 'success',
                      });
                    }}
                  >
                    Copiar Pix
                  </PaymentQrCodeButton>
                </QrCodeContainer>
              </ComponentIsVisible>

              <TermsMessage>
                Ao clicar em finalizar pedido você concorda com os{' '}
                <Link target="_blank" to={pages.terms}>
                  termos de uso{' '}
                </Link>{' '}
                e com a{' '}
                <Link target="_blank" to={pages.lgpdPolitic}>
                  política LGPD{' '}
                </Link>
                do evento
              </TermsMessage>
              <Forms.Actions gridTemplateColumns="8rem 1fr">
                <ComponentButtonBase
                  backgroundColor={colors.red700}
                  disabled={isLoading}
                  onClick={handleCancel}
                  type="button"
                >
                  Cancelar
                </ComponentButtonBase>
                <ComponentIsVisible
                  when={paymentMethodType !== EEventPaymentMethodType.Pix}
                >
                  <ComponentButtonBase isLoading={isLoading} type="submit">
                    Finalizar pedido
                  </ComponentButtonBase>
                </ComponentIsVisible>
                <ComponentIsVisible
                  when={paymentMethodType === EEventPaymentMethodType.Pix}
                >
                  <ComponentButtonBase
                    disabled={!!pixResponse}
                    isLoading={isLoadingPix}
                    type="submit"
                  >
                    Gerar QR Code
                  </ComponentButtonBase>
                </ComponentIsVisible>
              </Forms.Actions>
            </Card>
          </Content>
        </Container>
      </Containers.Global>
    </Main>
  );
};

export default EventDetails;
